import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet-async";
const Termandcondition = () => {
     useEffect(() => {
    document.title = "Terms & Conditions";
  }, []);
  return (
    <>
      <div className=" ">
        <div className=" mx-auto bg-white p-6 md:p-10 rounded-lg ">
          {/* Header */}
          <h1 className="text-4xl font-bold text-center text-blue-700">
            Terms & Conditions
          </h1>
          <p className="text-center text-gray-600 mt-3 text-lg italic">
            Please read these terms and conditions carefully before using our
            website.
          </p>

          {/* Introduction */}
          <div className="mt-10">
            <h2 className="text-2xl font-bold text-gray-800">
              📌 Introduction
            </h2>
            <p className="text-gray-700 mt-3 leading-relaxed">
              Welcome to NewsNew. By accessing this website, you agree to comply
              with these Terms and Conditions. If you do not agree, please do
              not use our website.
            </p>
          </div>

          {/* User Responsibilities */}
          <div className="mt-8">
            <h2 className="text-2xl font-bold text-gray-800">
              👤 User Responsibilities
            </h2>
            <p className="text-gray-700 mt-3 leading-relaxed">
              Users must use this website lawfully and ethically. - You may not
              copy, reproduce, or distribute our content without permission. -
              You must not engage in activities that disrupt the website's
              functionality. - Any misuse or hacking attempts will result in
              legal action.
            </p>
          </div>
          <div className="mt-8">
            <h2 className="text-2xl font-bold text-gray-800">
              🛡️ User Data & Privacy
            </h2>
            <p className="text-gray-700 mt-3 leading-relaxed">
              We value your privacy and ensure that user data is handled
              securely. By using our website, you acknowledge that some data may
              be collected, such as cookies, browsing history, and engagement
              metrics. However, we do not sell or share personal information
              without user consent.
            </p>
          </div>
          <div className="mt-8">
            <h2 className="text-2xl font-bold text-gray-800">
              📌 Limitation of Liability
            </h2>
            <p className="text-gray-700 mt-3 leading-relaxed">
              We strive to provide accurate and reliable content, but we do not
              guarantee uninterrupted access or error-free information. News New
              is not responsible for any direct or indirect damages resulting
              from the use of this website, including financial loss,
              reputational harm, or service interruptions.
            </p>
          </div>
          <div className="mt-8">
            <h2 className="text-2xl font-bold text-gray-800">
              📢 User-Generated Content
            </h2>
            <p className="text-gray-700 mt-3 leading-relaxed">
              If users submit comments, articles, or advertisements, they must
              ensure that the content is original, non-offensive, and legally
              compliant. We reserve the right to remove or modify any content
              that violates our guidelines.
            </p>
          </div>

          {/* Content Disclaimer */}
          <div className="mt-8">
            <h2 className="text-2xl font-bold text-gray-800">
              ⚠️ Content Accuracy & Liability
            </h2>
            <p className="text-gray-700 mt-3 leading-relaxed">
              We strive to provide accurate and up-to-date information, but we
              do not guarantee the correctness or completeness of the content.
              We shall not be held responsible for any inaccuracies or damages
              caused by reliance on our website’s information.
            </p>
          </div>

          {/* Third-Party Links */}
          <div className="mt-8">
            <h2 className="text-2xl font-bold text-gray-800">
              🔗 Third-Party Links & Ads
            </h2>
            <p className="text-gray-700 mt-3 leading-relaxed">
              Our website may contain third-party links or advertisements. We do
              not endorse or control the content of third-party websites, and we
              are not responsible for their policies or actions.
            </p>
          </div>

          {/* Advertisement & Monetization */}
          <div className="mt-8">
            <h2 className="text-2xl font-bold text-gray-800">
              📢 Advertising & Monetization
            </h2>
            <p className="text-gray-700 mt-3 leading-relaxed">
              News New may run advertisements, sponsored posts, and affiliate
              marketing. Advertisers are solely responsible for their claims,
              and users should exercise caution before engaging with any
              promotions.
            </p>
          </div>

          {/* Intellectual Property */}
          <div className="mt-8">
            <h2 className="text-2xl font-bold text-gray-800">
              📜 Intellectual Property Rights
            </h2>
            <p className="text-gray-700 mt-3 leading-relaxed">
              All content, including articles, logos, images, and graphics,
              belongs to NewsNew. Unauthorized use or reproduction without prior
              permission is strictly prohibited.
            </p>
          </div>

          {/* Termination of Access */}
          <div className="mt-8">
            <h2 className="text-2xl font-bold text-gray-800">
              ❌ Termination of Use
            </h2>
            <p className="text-gray-700 mt-3 leading-relaxed">
              We reserve the right to suspend or terminate user access if any
              violations of these terms occur.
            </p>
          </div>

          {/* Changes to Terms */}
          <div className="mt-8">
            <h2 className="text-2xl font-bold text-gray-800">
              🔄 Changes & Updates
            </h2>
            <p className="text-gray-700 mt-3 leading-relaxed">
              We may update these Terms & Conditions from time to time. Any
              changes will be posted on this page, and users are encouraged to
              review them periodically.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Termandcondition;
