import React from 'react'
import img from '../../Image/eventDemo.avif'
const LeftsidePoster = () => {
  return (
   <>
   <div>

       
        <div className="max-w-sm bg-white shadow-lg rounded-lg overflow-hidden lg:mt-22  lg:border-0 border-1 p-2">
      <img
        src={img}
        alt='newsnew'
        className="h-48 w-full object-cover  rounded-xl"
      />
      <div className="p-3">
        <h2 className="text-xl font-bold text-gray-900"> Event Name</h2>
        <p className="text-gray-600">📅 25-02-2025</p>
        <p className="text-gray-600">📍 Bhubaneswar ,odisha </p>
        <p className="text-green-600 font-semibold items-center">Coupon code : AXNCLDJUFMD</p>
        <a
          href='/event'
          className="mt-2 block text-center bg-blue-600 text-white py-1 rounded hover:bg-blue-700 transition"
        >
      Click Here
        </a>
      </div>
      <p className='items-center flex justify-center  text-lg'>Promote your Event </p>
      <a  href='https://api.whatsapp.com/send?phone=+91 8908042477&amp;forceIntent=true&amp;load=loadInIOSExternalSafari' target='\blank' className="mt-3 block text-center bg-green-600 text-white py-2 rounded hover:bg-green-700 transition cursor-pointer">Enqury Now 

      </a>
    </div>


        
   </div>
   
   
   </>
  )
}

export default LeftsidePoster