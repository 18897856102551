import React from "react";
import Divider from "../components/Divider";
import img from "../Image/comic1.avif";
import img2 from "../Image/eventDemo.avif";
import comichomepagelogoone from "../Image/comichomepagelogo.avif";
import Startupnews from "./Startupnews";
import latestnewsimg from "../Image/latestnewsposter.svg";
import LeftsidePoster from "../components/Advertisemet/LeftsidePoster";
import PostDisplay from "../components/Advertisemet/PostDisplay";
import { Helmet } from "react-helmet-async";
const Home = () => (
  <>
    <Helmet>
        <title>NewsNew</title>
        <meta name="description" content="Stay updated with the latest headlines, startup news, politics, and more." />
      </Helmet>
    <div className="lg:flex gap-2 p-1 ">
      {/* Sidebar */}
      <div className=" lg:min-w-[230px]  hidden lg:block  ">
        <div className="mt-4  ">
          <img
            src="https://media-hosting.imagekit.io//03cabe36b5c74bf6/230X320.png?Expires=1836915578&Key-Pair-Id=K2ZIVPTIP2VGHC&Signature=cn81kFmN~m2E~a4Gg7dSnlDNeJNXzA7-TPErIon8ZHYVcvlU-W0Mb5UAIWmW8LWGfoDMdK4L18i9pzWjuiHUBST59l8ohiX4AmuoVC0xOO67lunygqe6xzxKiRZlWOUVeIOpiSa3XqcKMLY56o4vGN2cGXt-u7os8K5f5oVYv1hyZJCLazKrMJOXM6p1SMTyMvlmuOr59gNX-IoJmky3IZP85HRgFXcAobH52T2AMponf6iZsgqok20QmK8SUAlVZ73hBNT6wH4~ClqclR4cHivutdyY8NOocqRXMwtnNbAXiqLvGNvZqc8GB~VMPGeD2TFHywK2MUzUeh94w~~NlQ__"
            alt="newsnew"
            className="h-80 object-cover rounded"
          />
        </div>

        <div>
          <div className="max-w-sw  shadow-lg rounded-lg overflow-hidden lg:mt-22  lg:border-0 border-1 ">
            <img
              src={img2}
              alt="newsnew"
              className="h-48 w-full object-cover p-1 rounded-xl "
            />
            <div className="p-4">
              <h2 className="text-xl font-bold text-gray-900">Event Name</h2>
              <p className="text-gray-600">📅 25-02-2025</p>
              <p className="text-gray-600">📍 Bhubaneswar ,odisha </p>
              <p className="text-green-600 font-semibold items-center">
                Coupon code : AXNCLDJUFMD
              </p>
              <a
                href="/event"
                className="mt-3 block text-center bg-blue-600 text-white py-1 rounded hover:bg-blue-700 transition"
              >
                Click Here
              </a>
            </div>
            <p className="items-center flex justify-center  text-lg">
              Promote your Event
            </p>
            <a
              href="https://wa.me/919114388358?text=I%20need%20your%20services"
              target="\blank"
              className="mt-2 block text-center bg-green-600 text-white py-1 rounded hover:bg-green-700 transition cursor-pointer "
            >
              Enqury Now
            </a>
          </div>
        </div>
      </div>

      {/* Link latest news section Section  */}
      <div className="">
        <a href={"/latest-news"}>
          <div className="lg:w-[970px] lg:max-w-[950px]  lg:p-4 p-3">
            {/* News Card */}
            <div className="    ">
              <p
                className="md:text-3xl  text-2xl   line-clamp-2 text-black
              max-md:text-xl hover:underline hover:text-blue-600 active:text-blue-600 active:underline"
              >
               Trump Stands Firm on Tariffs, Claims China Wants a Deal: 'We’re Waiting for Their Call
              </p>
            </div>
            <div className=" mt-3  group overflow-hidden rounded   ">
              {/* Image */}
              <img
                src={latestnewsimg}
                alt="News"
                className="h-auto w-full  transition-transform duration-500 group-hover:scale-110   "
              />
            </div>
            <p
              className="md:text-xl mt-3 break-all  text-black
             text-md hover:underline hover:text-blue-600 active:text-blue-600 active:underline"
            >
              WB SSC Scam | Supreme Court Overturns Calcutta HC Order for CBI Probe into Supernumerary Post Creation.
            </p>
          </div>
        </a>

        <div className=" lg:p-2  ">
          <Divider />

          <p className=" max-md:text-2xl text-3xl font-bold ">Live News : </p>
          <ul className="list-disc ml-5 text-xl max-md:text-lg  custom-disc animate-blink  lg:w-[900px] break-words p-2 max-md:break-all">
            <li>
             Congress to Undergo Massive Organisational Reshuffle, Says KC Venugopal; Party Pledges Commitment to Sardar Patel's Vision of Social Justice
            </li>
            <li>
             In the ongoing IPL 2025 match between Punjab Kings (PBKS) and Chennai Super Kings (CSK) at Mullanpur, CSK is striving to break a three-match losing streak. PBKS captain Shreyas Iyer won the toss and opted to bat first. 
            </li>
            <li>
             US Revokes Student Visas of Foreign Nationals at Top Universities Including Harvard, Tufts & Stanford, Sparking Concern on Campuses
            </li>
          </ul>
          <Divider />
          <div className="mt-12 p-2 block md:hidden ">
            <PostDisplay />
          </div>

          <Startupnews showLogos={true} showHelmet={false} />

          <Divider />

          <div className="p-6 md:flex justify-center lg:hidden">
            <LeftsidePoster />
          </div>

          {/* comic section */}
          <div className="p-4 md:p-8 bg-gray-100 text-black rounded-xl shadow border border-gray-300">
            {/* Main Heading */}
            <h2 className="text-center text-2xl md:text-3xl font-extrabold tracking-wide bg-clip-text text-transparent bg-gradient-to-r from-yellow-500 to-red-500">
              📖 Every Comic, A New Adventure! 🏹
            </h2>
            <p className="text-center text-lg md:text-xl italic mt-2">
              👑 Legends, Myths & More – Experience in Panels ✨
            </p>

            {/* Comic Card Wrapper */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-2 md:mt-6 p-4">
              {/* First Comic Section */}
              <a href={"/comic"} className="group block">
                <div className="bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-2xl transition duration-300">
                  <h3 className="text-xl md:text-2xl font-bold p-4 group-hover:text-yellow-500  group-active:text-yellow-500 group-focus-within:text-yellow-500 transition">
                    Chhatrapati Shivaji Maharaj The Legendary Warrior:
                  </h3>
                  <div className="overflow-hidden">
                    <img
                      src={img}
                      className="w-full h-64 md:h-72 object-cover rounded-b-lg transition-transform duration-500 group-hover:scale-105 active:scale-105"
                      alt="Shivaji Maharaj Comic"
                    />
                  </div>
                </div>
         </a>

              {/* Second Comic Section */}
              <a href={"/comic"} className="group block">
                <div className="bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-2xl transition duration-300">
                  <h3 className="text-xl md:text-2xl font-bold p-4 group-hover:text-purple-700 transition group-active:text-purple-700 ">
                    When You Push the Wrong Shopping Cart:
                  </h3>
                  <div className="overflow-hidden">
                    <img
                      src={comichomepagelogoone}
                      className="w-full h-64 md:h-72 object-cover rounded-b-lg transition-transform duration-500 group-hover:scale-105 active:scale-105"
                      alt="Comic Cover"
                    />
                  </div>
                </div>
              </a>
            </div>
          </div>

          {/* end */}

          <Divider />

          {/* politics and sport news */}
          <div className="flex flex-col md:flex-row gap-6 p-2 md:p-0 m mx-auto max-w-4xl">
            {/* Sports News Card */}
            <a
              href="/sports"
              className="w-full md:w-1/2 bg-gradient-to-r from-blue-600 to-blue-400 text-white  rounded-lg overflow-hidden transform hover:scale-105 active:scale-105 transition duration-300"
            >
              <div className="p-6">
                <h2 className="text-2xl font-bold">🏆 Sports </h2>
                <p className="mt-2 text-lg">
                  Catch the latest scores, and exclusive insights!
                </p>
                <button className="mt-4 bg-white text-blue-600 px-4 py-2 rounded-lg font-semibold hover:bg-blue-100 cursor-pointer">
                  Explore More →
                </button>
              </div>
            </a>

            {/* Politics News Card */}
            <a
              href="/politics"
              className="w-full md:w-1/2 bg-gradient-to-r from-red-600 to-red-400 text-white  rounded-lg overflow-hidden transform hover:scale-105 transition duration-300 active:scale-105"
            >
              <div className="p-6">
                <h2 className="text-2xl font-bold">🏛️ Politics </h2>
                <p className="mt-2 text-lg">
                  Get insights into government policies, and affairs.
                </p>
                <button className="mt-4 bg-white text-red-600 px-4 py-2 rounded-lg font-semibold hover:bg-red-100 cursor-pointer">
                  Explore More →
                </button>
              </div>
            </a>
          </div>
          <Divider />

          {/* Technology & Biography Section */}
          <div className="flex flex-col md:flex-row gap-6  mx-auto max-w-4xl p-2 md:p-0">
            <a
              href="/technology"
              className="w-full md:w-1/2 bg-gradient-to-r from-green-600 to-green-400 text-white  rounded-lg overflow-hidden transform hover:scale-105 transition duration-300 active:scale-105"
            >
              <div className="p-6">
                <h2 className="text-2xl font-bold">💻 Technology </h2>
                <p className="mt-2 text-lg">
                  Explore the latest tech trends, AI, and innovations.
                </p>
                <button className="mt-4 bg-white text-blue-600 px-4 py-2 rounded-lg font-semibold hover:bg-blue-100 cursor-pointer">
                  Explore More →
                </button>
              </div>
            </a>

            {/* Biography News Card */}
            <a
              href="/biograpy"
              className="w-full md:w-1/2 bg-gradient-to-r from-purple-600 to-purple-400 text-white  rounded-lg overflow-hidden transform hover:scale-105 transition duration-300 active:scale-105"
            >
              <div className="p-6">
                <h2 className="text-2xl font-bold">📜 Biography </h2>
                <p className="mt-2 text-lg">
                  Read about inspiring leaders, scientists, and pioneers.
                </p>
                <button className="mt-4 bg-white text-red-600 px-4 py-2 rounded-lg font-semibold hover:bg-red-100 cursor-pointer">
                  Explore More →
                </button>
              </div>
            </a>
          </div>
        </div>
      </div>

      <div className="lg:min-w-[320px] max-md:hidden  mt-14 md:text-center text-center ">
        <PostDisplay />
      </div>
      {/* comic section  */}
    </div>

    <style>
      {`
          @keyframes blink {
            0% { opacity: 1; }
            50% { opacity: 0; }
            100% { opacity: 1; }
          }
          
          .custom-disc li::marker {
            font-size: 30px;
             color: red;
             animation: blink 1s infinite;
          }
        
        `}
    </style>
  </>
);

export default Home;
