import React, { useState, useEffect } from 'react';
import {
  FaNewspaper,
  FaBullhorn,
  FaUsers,
} from "react-icons/fa";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async";
const AboutUs = () => {
   useEffect(() => {
    document.title = "About Us";
  }, []);
  return (
    <>


      <div className="bg-gray-100  p-6">
        {/* Hero Section */}
        <div className="text-center bg-gray-300 text-white py-12 rounded-xl ">
          <h1 className="text-3xl md:text-5xl font-extrabold text-gray-700">
            About Us
          </h1>
          <p className="text-lg md:text-xl mt-3 text-center text-black italic">
            Your Digital Destination for News, Promotions & Advertising Media
          </p>
        </div>

        {/* About Section */}
        <div className="mx-auto bg-white p-6 md:p-10 mt-5">
          <h2 className="text-2xl font-extrabold text-blue-700">
            🚀 Who We Are
          </h2>
          <p className="text-gray-700 mt-3">
            At <strong>News New</strong>, we bring you the latest news, powerful
            digital promotions, and premium advertising services. Whether it's{" "}
            <strong>
              event marketing, social media branding, or ad placements
            </strong>
            , we help businesses and individuals reach their audience
            effectively.
          </p>
          <h2 className="text-2xl font-extrabold text-blue-700 mt-6">
            🌍 Our Vision & Mission
          </h2>
          <p className="text-gray-700 mt-3">
            Our vision is to redefine the way news, promotions, and
            advertisements reach people. We aim to provide authentic, engaging,
            and valuable content that empowers individuals and businesses alike.
          </p>
          <p className="text-gray-700 mt-3">
            Our mission is to create a one-stop digital platform where users can
            stay informed, promote their brands, and explore innovative
            advertising solutions to maximize reach and impact.
          </p>

          <h2 className="text-2xl font-extrabold text-blue-700 mt-6">
            📊 How We Make a Difference
          </h2>
          <p className="text-gray-700 mt-3">
            Unlike traditional platforms, we combine news, digital marketing,
            and advertising into a single ecosystem. Whether you're a startup
            looking for exposure, a business launching a campaign, or an
            individual promoting an event, we provide customized solutions that
            drive engagement and results.
          </p>

          <h2 className="text-2xl font-extrabold text-blue-700 mt-6">
            🔍 Our Content & Advertising Standards
          </h2>
          <p className="text-gray-700 mt-3">
            We prioritize quality content, ensuring all our news, articles, and
            promotions are fact-checked, relevant, and insightful. Advertisers
            can expect a transparent, high-performing environment where their
            brands are showcased to the right audience at the right time.
          </p>
          <p className="text-gray-700 mt-3">
            Our advertising policies are designed to meet Google AdSense
            compliance, ensuring ads are ethical, user-friendly, and optimized
            for engagement.
          </p>

          <h2 className="text-2xl font-extrabold text-blue-700 mt-6">
            🌟 Join the News New Community
          </h2>
          <p className="text-gray-700 mt-3">
            Become a part of our ever-growing network of readers, advertisers,
            and digital marketers. Follow us for exclusive insights,
            expert-driven strategies, and the latest trends in digital
            advertising, news, and promotions.
          </p>
        </div>

        {/* Why Choose Us Section */}
        <div className="mt-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <motion.div
            whileHover={{ scale: 1.05 }}
            className="bg-white p-6 rounded-lg shadow-lg text-center"
          >
            <FaNewspaper className="text-4xl text-blue-600 mx-auto" />
            <h3 className="font-bold mt-3">Real-Time News</h3>
            <p className="text-sm text-gray-600">
              Stay updated with the latest news & insights.
            </p>
          </motion.div>

          <motion.div
            whileHover={{ scale: 1.05 }}
            className="bg-white p-6 rounded-lg shadow-lg text-center"
          >
            <FaBullhorn className="text-4xl text-blue-600 mx-auto" />
            <h3 className="font-bold mt-3">Digital Promotions</h3>
            <p className="text-sm text-gray-600">
              Boost your brand with expert digital strategies.
            </p>
          </motion.div>

          <motion.div
            whileHover={{ scale: 1.05 }}
            className="bg-white p-6 rounded-lg shadow-lg text-center"
          >
            <FaUsers className="text-4xl text-blue-600 mx-auto" />
            <h3 className="font-bold mt-3">Engaging Events</h3>
            <p className="text-sm text-gray-600">
              Promote events with visually stunning posters.
            </p>
          </motion.div>

          <motion.div
            whileHover={{ scale: 1.05 }}
            className="bg-white p-6 rounded-lg shadow-lg text-center"
          >
            <FaBullhorn className="text-4xl text-blue-600 mx-auto" />
            <h3 className="font-bold mt-3">Effective Ads</h3>
            <p className="text-sm text-gray-600">
              High-impact advertising for maximum ROI.
            </p>
          </motion.div>
        </div>

        {/* Contact Section */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          className="mt-16 text-center bg-gradient-to-r from-purple-500 to-indigo-600  text-white p-8 rounded-xl shadow-lg"
        >
          <h2 className="text-2xl font-extrabold"> Contact Us</h2>
          <p className="text-lg mt-2">
            Want to promote your brand or publish an ad? Get in touch now!
          </p>
          <div className="mt-4 flex flex-col items-center">
            <a
              href="tel:+91 9114388358"
              className="mt-2 bg-white text-purple-600 font-semibold py-2 px-6 rounded-full shadow-md hover:bg-gray-200 transition"
            >
              📞 +91 9114388358
            </a>

            <a
              href="https://wa.me/919114388358?text=I%20need%20your%20services"
              target="_blank"
              rel="noopener noreferrer"
              className="mt-3 flex items-center bg-green-500 text-white font-semibold py-2 px-6 rounded-full shadow-md hover:bg-green-600 transition"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="mr-2"
              >
                <path d="M12 2C6.48 2 2 6.48 2 12c0 1.95.51 3.79 1.4 5.4L2 22l4.67-1.27A9.953 9.953 0 0 0 12 22c5.52 0 10-4.48 10-10S17.52 2 12 2zm.03 17.3c-1.76 0-3.43-.46-4.89-1.3l-.35-.21-2.76.75.79-2.63-.24-.38A8 8 0 1 1 12 20zm4.4-5.97c-.24-.12-1.42-.7-1.64-.78-.22-.08-.38-.12-.54.12s-.62.78-.76.94c-.14.16-.28.18-.52.06-.24-.12-1.03-.38-1.96-1.22-.72-.64-1.21-1.43-1.35-1.67-.14-.24-.01-.36.11-.48.11-.11.24-.28.36-.41s.16-.24.24-.4c.08-.16.04-.3-.02-.42-.06-.12-.54-1.3-.74-1.78-.2-.48-.4-.41-.54-.42h-.46c-.16 0-.42.06-.64.3s-.84.82-.84 2c0 1.18.86 2.31.98 2.48.12.17 1.69 2.66 4.1 3.72 1.44.62 2 .67 2.72.57.44-.06 1.42-.58 1.63-1.14.2-.56.2-1.04.14-1.14-.06-.1-.24-.16-.48-.28z"></path>
              </svg>
              Chat on WhatsApp
            </a>

            <a
              href="mailto:sahoodevraj25@gmail.com"
              className="mt-2 bg-white text-purple-600 font-semibold py-2 px-6 rounded-full shadow-md hover:bg-gray-200 transition"
            >
              📩 sahoodevraj25@gmail.com
            </a>
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default AboutUs;

