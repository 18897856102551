const Techonolgydata = {
    data: [
      {
        _id: "1",
        techName: "Artificial Intelligence",
        techDesc: "Artificial Intelligence (AI) is transforming the way we live, work, and interact with technology. Unlike traditional software, AI systems can learn, adapt, and make decisions based on data. From automating repetitive tasks to enhancing decision-making in industries like healthcare, finance, and logistics, AI is reshaping the modern world. Innovations like machine learning, natural language processing, and computer vision enable AI to understand human speech, recognize images, and even predict future trends. While AI brings immense benefits, ethical concerns such as data privacy, bias, and job displacement remain critical issues. The future of AI lies in responsible development, ensuring that technology serves humanity while maintaining transparency and fairness.",
        techImage: "/Image/ai.avif",
      },
      {
        _id: "2",
        techName: "Blockchain Technology",
        techDesc: "Blockchain technology is revolutionizing digital transactions by providing a decentralized, secure, and transparent way to record and verify data. Unlike traditional databases controlled by central authorities, blockchain operates on a distributed ledger system, where each transaction is encrypted and linked to the previous one, ensuring immutability and security. This technology is widely used in cryptocurrencies like Bitcoin and Ethereum but extends beyond finance, impacting industries such as supply chain management, healthcare, and real estate. By eliminating intermediaries, blockchain enhances efficiency, reduces fraud, and promotes trust in digital interactions. As innovation continues, blockchain is poised to redefine how data is stored, shared, and secured in the digital era.",
        techImage: "/Image/BT.avif",
      },
      {
        _id: "3",
        techName: "Quantum Computing",
        techDesc: "Quantum computing is a groundbreaking technology that leverages the principles of quantum mechanics to process information at unprecedented speeds. Unlike classical computers, which use bits (0s and 1s), quantum computers use qubits that can exist in multiple states simultaneously due to superposition. This enables them to solve complex problems, such as cryptography, material simulation, and optimization, much faster than traditional systems. Additionally, quantum entanglement allows qubits to be interconnected, leading to exponentially higher computational power. While still in its early stages, quantum computing has the potential to revolutionize industries like healthcare, artificial intelligence, and cybersecurity, pushing the boundaries of what is computationally possible.",
        techImage: "/Image/QC.png",
      },
      {
        _id: "4",
        techName: "Cybersecurity",
        techDesc: "Cybersecurity is the backbone of digital safety, protecting sensitive data and systems from cyber threats in an increasingly interconnected world. With the rise of cyberattacks, including ransomware, phishing, and data breaches, organizations and individuals must adopt robust security measures. Advanced technologies like AI-driven threat detection, blockchain-based security, and zero-trust architecture are reshaping cybersecurity strategies. Encryption, multi-factor authentication, and ethical hacking play crucial roles in safeguarding digital assets. As cybercriminals evolve, proactive cybersecurity approaches are essential to ensure data privacy, business continuity, and national security. The future of cybersecurity lies in adaptive defenses, continuous monitoring, and a collaborative effort to outpace cyber threats.",
        techImage: "/Image/CYBER.avif",
      },
      {
        _id: "5",
        techName: "5G Technology",
        techDesc: "5G technology is revolutionizing global connectivity by offering ultra-fast speeds, low latency, and massive device connectivity. Unlike its predecessors, 5G operates on higher-frequency bands, enabling real-time data transmission essential for innovations like autonomous vehicles, smart cities, and the Internet of Things (IoT). Its enhanced capacity supports seamless video streaming, cloud gaming, and industrial automation, transforming digital experiences. Beyond speed, 5G improves network efficiency, reducing congestion and ensuring reliable connections even in densely populated areas. As industries integrate 5G into their ecosystems, it will unlock new possibilities in healthcare, remote work, and artificial intelligence, shaping the future of a hyper-connected world.",
        techImage: "/Image/5G.avif",
      },
      {
        _id: "6",
        techName: "Edge Computing",
        techDesc: "Edge computing is transforming data processing by bringing computation closer to the source of data rather than relying on centralized cloud servers. This reduces latency, improves real-time processing, and enhances security. Industries such as healthcare, IoT, and autonomous vehicles benefit from edge computing by enabling faster decision-making and reducing bandwidth costs.",
        techImage: "/Image/EDGE.avif",
      },
      {
        _id: "7",
        techName: "Internet of Things (IoT)",
        techDesc: "The Internet of Things (IoT) connects everyday devices to the internet, allowing them to collect, share, and analyze data. From smart homes and wearable devices to industrial automation, IoT enhances efficiency, optimizes resource utilization, and improves user experiences. As IoT adoption grows, security and data privacy remain key challenges.",
        techImage: "/Image/IOT.avif",
      },
      {
        _id: "8",
        techName: "Machine Learning (ML)",
        techDesc: "AI and ML are revolutionizing industries by enabling machines to learn from data and make intelligent decisions. From personalized recommendations and fraud detection to autonomous systems and medical diagnosis, AI-driven applications are reshaping businesses. As AI advances, ethical considerations like bias, transparency, and job automation must be addressed.",
        techImage: "/Image/MC.avif",
      },
      {
        _id: "9",
        techName: "Biometric Technology",
        techDesc: "Biometric technology enhances security by using unique biological traits like fingerprints, facial recognition, and iris scans for authentication. From unlocking smartphones to secure banking transactions, biometrics improves identity verification and reduces fraud. As biometric systems become widespread, ensuring data privacy and preventing misuse are critical challenges for the future.",
        techImage: "/Image/BIOMETRIC.avif",
      },
    ],
  };
  
  export default Techonolgydata;
  