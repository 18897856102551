import React, { useEffect, useState, useCallback } from "react";
import Axios, { storesAllApi } from "../common/storeapi";
import Divider from "../components/Divider";
import { Helmet } from "react-helmet-async";

const SkeletonLoader = () => (
  <div className="p-4 bg-white shadow-lg rounded-lg animate-pulse w-full max-w-5xl ">
    <div className="h-8 bg-gray-300 rounded-md mb-4 w-3/4"></div>
    <div className="h-56 bg-gray-300 rounded-md w-full mb-4"></div>
    <div className="h-6 bg-gray-300 rounded-md mb-2 w-5/6"></div>
    <div className="h-6 bg-gray-300 rounded-md mb-4 w-4/5"></div>
    <div className="h-56 bg-gray-300 rounded-md w-full mb-4"></div>
    <div className="h-6 bg-gray-300 rounded-md mb-2 w-5/6"></div>
    <div className="h-6 bg-gray-300 rounded-md w-4/5"></div>
  </div>
);

const LatestNews = () => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchLatestNews = useCallback(async () => {
    const controller = new AbortController();
    const signal = controller.signal;

    try {
      const response = await Axios({
        url: storesAllApi.getlatestnewsdetailsAll.url,
        method: storesAllApi.getlatestnewsdetailsAll.method,
        signal,
        headers: { Connection: "keep-alive" },
      });

      if (response.data.success) {
        setNews(response.data.data);
      } else {
        console.error("Failed to fetch news:", response.data.message);
      }
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error("Error fetching latest news:", error);
      }
    } finally {
      setLoading(false);
    }

    return () => controller.abort();
  }, []);

  useEffect(() => {
    fetchLatestNews();
  }, [fetchLatestNews]);

  return (
    <>
      <Helmet>
        <title>Latest News </title>
        <meta name="description" content="Stay updated with the latest breaking news and trending stories, brought to you by NewsNew – your trusted news source." />
        <meta name="keywords" content="latest news, breaking news, trending news, today headlines, NewsNew updates" />
        <meta name="author" content="NewsNew" />
        <meta property="og:title" content="Latest Breaking News – NewsNew" />
        <meta property="og:description" content="Catch all the trending news and stories happening around the world, updated in real-time." />
        <meta property="og:image" content="https://newsnew.in/images/og-latest.jpg" />
        <meta property="og:url" content="https://newsnew.in/latest-news" />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Latest News – NewsNew" />
        <meta name="twitter:description" content="Explore today's trending news, breaking updates and important headlines – all in one place." />
        <meta name="twitter:image" content="https://newsnew.in/images/og-latest.jpg" />
      </Helmet>

       <div className='flex flex-col items-center min-h-screen'>
      {loading ? (
        <SkeletonLoader />
      ) : news.length > 0 ? (
        news.map((item) => (
          <div key={item._id} className="w-full max-w-5xl p-4 bg-white lg:p-6 shadow-md">
            <h2 className="lg:text-4xl text-2xl font-semibold mb-4">
              {item.latestNewsHeading}
            </h2>
            <div className="aspect-[18/10] md:aspect-[25/10]">
              <img
                src={item.latestfirstImage}
                alt={item.latestNewsHeading}
                className="size-full object-cover rounded-2xl"
              />
            </div>
            <p className="text-lg p-2 max-md:leading-tight">{item.latestFirstParagraph}</p>
            <p className="text-lg p-2 mb-5 max-md:leading-tight">{item.latestSecondParagraph}</p>
            <Divider />
          </div>
        ))
      ) : (
        <div className="mt-2 w-full h-full text-black flex justify-center items-center p-6">
          <h1 className="text-2xl">⏳ Loading fresh news for you... Please stay with us!</h1>
        </div>
      )}
    </div>
    </>
 
  );
};

export default LatestNews;
