import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet-async";
const PrivacyPolicy = () => {
    useEffect(() => {
    document.title = "Privacy Policy";
  }, []);
  return (
    <>
     <div className="bg-gray-200 text-gray-900    ">
      <div className=" mx-auto bg-white p-6 md:p-10 ">
        <h1 className="text-3xl font-bold  text-blue-700 text-center">Privacy Policy</h1>
        <p className=" text-gray-600 mt-2 text-center">Last Updated: March 2025</p>

        <section className="mt-6">
          <h2 className="text-xl font-semibold text-gray-800">1. Introduction</h2>
          <p className="text-gray-700 mt-2">
            Welcome to <strong>News New</strong>. We value your privacy and are committed to protecting your personal data. 
            This policy explains how we collect, use, and safeguard your information.
          </p>
        </section>

        <section className="mt-6">
          <h2 className="text-xl font-semibold text-gray-800">2. Information We Collect</h2>
          <p className="text-gray-700 mt-2">We may collect the following types of information:</p>
          <ul className="list-disc pl-5 text-gray-700 mt-2">
            <li>Personal details (name, email, contact number) when you contact us.</li>
            <li>Browsing data (IP address, device type, cookies) to enhance user experience.</li>
            <li>Advertising-related data for tailored promotions.</li>
          </ul>
        </section>

        <section className="mt-6">
          <h2 className="text-xl font-semibold text-gray-800">3. How We Use Your Information</h2>
          <p className="text-gray-700 mt-2">We use collected data for:</p>
          <ul className="list-disc pl-5 text-gray-700 mt-2">
            <li>Providing news, events, and advertisements.</li>
            <li>Enhancing website functionality and user experience.</li>
            <li>Complying with legal requirements and security purposes.</li>
          </ul>
        </section>

        <section className="mt-6">
          <h2 className="text-xl font-semibold text-gray-800">4. Third-Party Services</h2>
          <p className="text-gray-700 mt-2">
            We may use third-party services (e.g., Google Ads, Analytics) to improve our website. These services may collect 
            anonymous data as per their policies.
          </p>
        </section>

        <section className="mt-6">
          <h2 className="text-xl font-semibold text-gray-800">5. Cookies & Tracking</h2>
          <p className="text-gray-700 mt-2">
            We use cookies to personalize content, analyze traffic, and enhance user experience. You can manage your cookie preferences in your browser settings.
          </p>
        </section>

        <section className="mt-6">
          <h2 className="text-xl font-semibold text-gray-800">6. Data Security</h2>
          <p className="text-gray-700 mt-2">
            We implement strict security measures to protect your data from unauthorized access or misuse.
          </p>
        </section>

        <section className="mt-6">
          <h2 className="text-xl font-semibold text-gray-800">7. Your Rights</h2>
          <p className="text-gray-700 mt-2">You have the right to:</p>
          <ul className="list-disc pl-5 text-gray-700 mt-2">
            <li>Access, modify, or delete your personal data.</li>
            <li>Opt-out of data collection by adjusting cookie settings.</li>
          </ul>
        </section>

        <section className="mt-6">
          <h2 className="text-xl font-semibold text-gray-800">8. Changes to This Policy</h2>
          <p className="text-gray-700 mt-2">
            We may update this policy occasionally. Any changes will be posted on this page with the updated date.
          </p>
        </section>

        <section className="mt-6">
          <h2 className="text-xl font-semibold text-gray-800">9. Contact Us</h2>
          <p className="text-gray-700 mt-2">
            If you have any questions about this policy, please contact us at:
          </p>
          <p className="text-gray-800 font-semibold mt-2">📧 Email: sahoodevraj25@gmail.com</p>
          <p className="text-gray-800 font-semibold">📞 Phone: +91 9114388358</p>
        </section>

     
      </div>
    </div>
    
    
    
    </>
   
  )
}

export default PrivacyPolicy
