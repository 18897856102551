import React, { useCallback, useEffect, useState } from "react";
import { MdContentCopy } from "react-icons/md";
import Axios, { storesAllApi } from "../common/storeapi";
import img from "../Image/eventmarketing.avif";
import { Helmet } from 'react-helmet-async';
const Events = () => {
  const [loading, setLoading] = useState(true);
  const [event, setEvent] = useState([]);
  const [copiedId, setCopiedId] = useState(null);

  const fetchEventDetails = useCallback(async () => {
    const controller = new AbortController();
    const signal = controller.signal;
    try {
      const response = await Axios({
        url: storesAllApi.gteEventDetails.url,
        method: storesAllApi.gteEventDetails.method,
        signal,
        headers: {
          Connection: "keep-alive",
        },
      });

      if (response.data.success) {
        setEvent(response.data.data);
      } else {
        console.error("Failed to fetch events:", response.data.message);
      }
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error("Error fetching events:", error);
      }
    } finally {
      setLoading(false);
    }

    return () => controller.abort();
  }, []);

  useEffect(() => {
    fetchEventDetails();
  }, [fetchEventDetails]);

  const handleCopy = (coupon, id) => {
    navigator.clipboard.writeText(coupon);
    setCopiedId(id);
    setTimeout(() => setCopiedId(null), 500);
  };

  return (
    <>
      <Helmet>
        <title>
         Upcoming Events Near You 
        </title>
        <meta
          name="description"
          content="Explore exciting local and global events including concerts, expos, meetups & more. Book tickets, grab exclusive discounts, and promote your own events with ease!"
        />
        <meta
          name="keywords"
          content="Events near me, book event tickets, event promotions, concerts, business events, startup expo, free event listing, event discounts, local gatherings"
        />
        <meta
          property="og:title"
          content="🎉 Discover Events Near You | NewsNew"
        />
        <meta
          property="og:description"
          content="Find and promote exciting events in your area. Get discounts, coupon codes, and more!"
        />
        <meta property="og:image" content={img} />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://newsnew.in/event" />
      </Helmet>

      <div className="lg:p-12  p-4 ">
        <h1 className="text-center text-3xl  lg:text-4xl font-bold ">
          🎉 Discover & Promote Events Near You! 🎉
        </h1>
        <p className="text-center text-xl mt-2 lg:mt-4 italic p-4">
          Looking for exciting events happening around you? Find, book, and
          experience amazing events with just one click! Whether it's concerts,
          business summits, startup expos, or community gatherings, we’ve got
          them all !
        </p>
        <p className="text-center text-xl mt-2 lg:mt-4 italic ">
          Looking to get your event noticed? We help you{" "}
          <span className="text-blue-600 font-bold">
            maximize visibility and engagement
          </span>{" "}
          by promoting your event across multiple platforms.
        </p>
      </div>
      <div className="flex flex-col lg:flex-row items-center justify-center gap-6 p-4 lg:p-12 bg-gray-100">
        <div className="">
          <img
            className="rounded-2xl p-2 lg:h-78  w-full"
            src={img}
            alt="Image"
            width="400"
            height="100"
          />
        </div>
        <div>
          <div className="p-3 lg:p-5 w-full  lg:h-25 shadow bg-blue-100 rounded-2xl ">
            <h2 className="text-xl font-bold lg:text-2xl">
              🌐 Featured Event Listing{" "}
            </h2>
            <p className="">
              Get your event listed on our website to attract a larger audience
              and enhance visibility.
            </p>
          </div>
          <div className="p-3 lg:p-5 w-full  lg:h-25 shadow bg-cyan-100 rounded-2xl mt-4">
            <h2 className="text-xl font-bold lg:text-2xl">
              📢 Multi-Platform Promotion{" "}
            </h2>
            <p>
              We promote your event through our website, social media, and
              partner networks to maximize reach.
            </p>
          </div>
          <div className="p-3 lg:p-5 w-full  lg:h-25 shadow bg-green-100 rounded-2xl  mt-4">
            <h2 className="text-xl font-bold lg:text-2xl">
              📍 Local & Global Reach{" "}
            </h2>
            <p>
              Expand your event’s footfall by reaching both local attendees and
              a global audience.
            </p>
          </div>
        </div>
      </div>
      <div className="lg:p-12  p-3 mt-5">
        <p className="text-center text-xl mt-2 lg:mt-4 font-semibold">
          {" "}
          Get Exclusive Discounts! Book your tickets now and unlock special
          coupon codes for amazing deals.
        </p>
        <p className="text-center  mt-2 lg:mt-4 font-semibold font-sans italic">
          {" "}
          🚀 Enjoy exclusive early-bird discounts, special coupon codes, and
          limited-time deals ! 🚀
        </p>
        <p className="text-center  mt-6 lg:mt-8 font-semibold font-sans animate-bounce">
          👉 Don't miss out – Click "Book Now" & Secure Your Spot Today! 🎟️🔥
        </p>
      </div>

      {/* fetch data section */}

      <div className="flex justify-center  flex-wrap gap-6 p-6 md:p-0">
        {loading ? (
          // Skeleton Loader
          Array(1)
            .fill(0)
            .map((_, index) => (
              <div
                key={index}
                className="w-72 md:w-80 rounded-xl overflow-hidden shadow-lg bg-white  flex flex-col min-h-[450px]"
              >
                <div className="w-full h-52 bg-gray-300 rounded"></div>
                <div className="mt-4 h-6 bg-gray-300 rounded"></div>
                <div className="mt-2 h-4 bg-gray-300 rounded w-3/4"></div>
                <div className="mt-2 h-4 bg-gray-300 rounded w-1/2"></div>
                <div className="mt-4 h-10 bg-gray-300 rounded w-full"></div>
              </div>
            ))
        ) : event.length > 0 ? (
          event.map((item) => (
            <div
              key={item._id}
              className="w-72 md:w-80 rounded-xl overflow-hidden shadow-lg bg-white  flex flex-col min-h-[450px]"
            >
              {/* Event Image */}
              <img
                className="w-full h-52 object-cover rounded"
                src={item.eventImage}
                alt={item.eventName}
              />

              {/* Event Details (Flex-Grow Ensures Fixed Height) */}
              <div className="flex flex-col flex-grow p-3">
                <h2 className="text-lg font-bold mt-2 text-ellipsis overflow-hidden">
                  {item.eventName}
                </h2>
                <p className="text-gray-600 flex items-center mt-2">
                  📅 {item.eventDate}
                </p>
                <p className="text-gray-600 flex items-center mt-2">
                  📍 {item.eventLocation}
                </p>

                {/* Coupon Code Section */}
                <div className="mt-2">
                  <p className="font-semibold">Coupon Code:</p>
                  <div className="flex justify-between items-center">
                    <p className="text-white border w-full p-1 rounded bg-gray-400 text-center">
                      {item?.eventCoupon}
                    </p>
                    <span
                      className="cursor-pointer text-white bg-gray-500 p-1 rounded hover:bg-gray-400"
                      onClick={() => handleCopy(item?.eventCoupon, item._id)}
                    >
                      <MdContentCopy size={20} />
                    </span>
                    {copiedId === item._id && (
                      <span className="text-green-400 text-sm">Copied!</span>
                    )}
                  </div>
                </div>

                {/* Book Now Button (Stays at Bottom) */}
                <div className="flex justify-center mt-4">
                  <a
                    href={item?.eventLink}
                    target="_blank"
                    className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 text-center w-36 flex justify-center items-center"
                  >
                    Book Now
                  </a>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="mt-2 w-full h-full text-black flex justify-center items-center p-6">
            <h1 className="text-2xl">
              ✨ The stage is almost set… Stay tuned for upcoming events! .
            </h1>
          </div>
        )}
      </div>
      <div>
        <p className="text-center text-lg md:mt-10 mt-3 ">
          📅 Stay Updated: Upcoming Events You Can’t Miss!
        </p>
      </div>
    </>
  );
};

export default Events;
