import axios from 'axios';

export const baseURL = "https://backend0121.newsnew.in"; // Keep only the base domain
// https://newsnewbackend01.vercel.app
// http://localhost:5000/
const Axios = axios.create({
    baseURL: baseURL,  // Use this as the base for all API calls
    withCredentials: true, // Ensure cookies are sent if required
    headers: { "Content-Type": "application/json" }
});

export default Axios;

const storesAllApi = {
    getlatestnewsdetailsAll: {
        url: "/api/user/getalllatestdetails",  
        method: "get",
    },
    requestPostDetails: {
        url: "/api/user/create-requestPost", 
        method: "post",
    }, 
    getAllOccsionDetails :{
        url :"/api/user/getOccusionDetails",
         method:"get"
     },
     getAllComicDataDetails : {
        url :"/api/user/getComicDetails",
        method:"get"
    },
    gteEventDetails : {
        url :"/api/user/geteventdetails",
        method : "get"
    },
    getSportDetails:{
        url : "/api/user/getAllSportDetails",
       method:"get"
   },
   getPoliticsDetails:{
    url :"/api/user/getAllPolitics",
    method:"get"
    },
  getLiveDetails: {
    url: "/api/user/livedetails",
    method: "get",
   },
   getPromoAllDetails :{
    url :"/api/user/getPromoAllDetails",
   method:"get"
   }, 
};

export { storesAllApi };
