import React, { useState, useRef, useEffect } from 'react';
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import Axios from '../../common/storeapi';
import { storesAllApi } from '../../common/storeapi';
import AxiosToastError from '../../utils/AxiosToastError';

const PostRequestForm = ({ setOpenForm }) => {
    const navigate = useNavigate();
    const fileInputRef = useRef(null);
    const [submitting, setSubmitting] = useState(false);
    const [reqData, setReqData] = useState({
        requestPostHeading: "",
        requestPostName: "",
        requestPostNumber: "",
        requestPostDate: "",
        requestPostDesc: "",
        requestPostImage: null,
    });

    useEffect(() => {
        document.body.classList.add("overflow-hidden");
        return () => document.body.classList.remove("overflow-hidden");
    }, []);

    const handleChange = (e) => {
        const { name, type, value, files } = e.target;

        if (type === "file") {
            const file = files[0];

            if (file && file.size > 1000 * 1024) {
                toast.error("File size must be less than 1MB");
                return;
            }

            setReqData((prevData) => ({
                ...prevData,
                requestPostImage: file,
            }));
        } else if (name === "requestPostDate") {
            const date = new Date(value);
            const formattedDate = date.toLocaleDateString("en-GB").replace(/\//g, "-");
            setReqData((prevData) => ({
                ...prevData,
                requestPostDate: formattedDate,
            }));
        } else {
            setReqData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitting(true);

        try {
            const formData = new FormData();
            formData.append("requestPostHeading", reqData.requestPostHeading);
            formData.append("requestPostName", reqData.requestPostName);
            formData.append("requestPostNumber", reqData.requestPostNumber);
            formData.append("requestPostDate", reqData.requestPostDate);
            formData.append("requestPostDesc", reqData.requestPostDesc);

            if (reqData.requestPostImage) {
                formData.append("requestPostImage", reqData.requestPostImage);
            } else {
                toast.error("Please upload an image");
                setSubmitting(false);
                return;
            }

            const response = await Axios.post(storesAllApi.requestPostDetails.url, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            });

            if (response.data.success) {
                toast.success(response.data.message);
                setReqData({
                    requestPostHeading: "",
                    requestPostName: "",
                    requestPostNumber: "",
                    requestPostDate: "",
                    requestPostDesc: "",
                    requestPostImage: null,
                });

                if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                }
                setOpenForm(false);
                navigate("/");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            AxiosToastError(error);
        } finally {
            setSubmitting(false);
        }
    };

    const handleClose = () => {
        setOpenForm(false);
        navigate('/');
    };

    return (
        <div className='fixed inset-0 flex justify-center items-center bg-black/50 z-[60] p-5 lg:p-0 ' >
            <div className='w-full max-w-md bg-white shadow-2xl p-4 relative mt-4'>
                <IoCloseSharp
                    className='absolute  right-3 cursor-pointer text-gray-600 hover:text-red-600'
                    size={28}
                    onClick={handleClose}
                />
                <h1 className='text-center font-bold text-xl mb-2'>Add New Post Request!</h1>
                <form className='grid gap-3' onSubmit={handleSubmit}>
                    <input className='border p-2 rounded w-full' type='text' name='requestPostHeading' value={reqData.requestPostHeading} onChange={handleChange} placeholder='Occasion Name (like Birthday, Missing, Festival, YouTube Promotion, etc.)' required />
                    <input className='border p-2 rounded w-full' type='text' name='requestPostName' value={reqData.requestPostName} onChange={handleChange} placeholder='Name' />
                    <input type='date' name='requestPostDate' value={reqData.requestPostDate.split("-").reverse().join("-")} className='border p-2 rounded w-full' onChange={handleChange} required />
                    <input type='file' name='requestPostImage' onChange={handleChange} className='border p-2 rounded w-full' ref={fileInputRef} />
                    <input className='border p-2 rounded w-full' type='text' name='requestPostNumber' value={reqData.requestPostNumber} onChange={handleChange} placeholder='Contact Number (Optional)' />
                    <textarea className='border p-2 rounded w-full' name='requestPostDesc' rows={3} value={reqData.requestPostDesc} onChange={handleChange} placeholder='Description or Quote'></textarea>
                    
                    <button type='submit' className={`cursor-pointer bg-green-500 text-white py-2 rounded hover:bg-green-700 transition-all ${submitting ? 'opacity-50 cursor-not-allowed' : ''}`} disabled={submitting}>
                        {submitting ? 'Submitting...' : 'Submit'}
                    </button>
                    <button type='button' className=' cursor-pointer bg-blue-500 text-white py-2 rounded hover:bg-blue-700 transition-all' onClick={handleClose}>Close Form</button>
                </form>
            </div>
        </div>
    );
};

export default PostRequestForm;
