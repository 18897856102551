import React from "react";
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import { StartupData } from "../common/startupData";
import fydoimgblack from "../Image/fydoblack.avif";
import fydoimgcolor from "../Image/fydocolor.avif";
import venewoneblack from "../Image/venewoneblack.avif";
import venewonecolor from "../Image/venewonegreen (1).avif";
import messkacolorlogo from "../Image/mesekacolorlogo.svg";
import messkablacklogo from "../Image/messokablacklogos.svg";
import { Helmet } from "react-helmet-async";

const Startupnews = ({ showHelmet = true, showLogos }) => {
  return (
    <>
      {showHelmet && (
        <Helmet>
          <title>Startup News </title>
          <meta name="description" content="Explore the latest in the world of startups." />
        </Helmet>
      )}
      <div className="mt-5 p-6 bg-blue-100 rounded-2xl shadow">
        <h1 className="font-bold text-2xl md:text-3xl text-center text-gray-800">
          🌍 Global Startup News | ⚡Fast-Growing Startups
        </h1>
        <p className="text-center text-gray-600 italic mt-2">
          Deep dive into the strategies fueling startup success stories.
        </p>

        <div className="flex flex-wrap justify-center gap-3 md:gap-8 mt-6">
          {StartupData.map((startup, index) => (
            <a key={index} href={`/startupnews/${startup.id}`}>
              <div className="block w-full sm:w-4/5 lg:w-1/3">
                <div className="bg-white rounded-2xl shadow-lg hover:shadow-xl transition-all p-4 flex gap-4 items-center md:w-80">
                  <div className="h-24 w-24 md:h-28 md:w-28 rounded-xl overflow-hidden border-1">
                    <img
                      src={startup.img}
                      className="h-full w-full object-cover rounded-xl"
                      alt={startup.name}
                    />
                  </div>
                  <div className="flex-1">
                    <h2 className="font-bold text-lg md:text-xl text-gray-900 hover:underline hover:text-blue-600">
                      {startup.name}
                    </h2>
                    <p className="text-gray-700 text-sm md:text-base">
                      Founded: {startup.foundeddate}
                    </p>
                    <div className="flex justify-between items-center mt-2 text-gray-500">
                      <p className="text-xs md:text-sm">Updated: Jun 2, 2024</p>
                      <FaRegArrowAltCircleRight
                        size={25}
                        className="text-blue-500 hover:animate-ping"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </a>
          ))}
        </div>

        {showLogos && (
          <div className="hidden lg:flex justify-center gap-10 mt-10">
            {[
              { black: fydoimgblack, color: fydoimgcolor },
              { black: messkablacklogo, color: messkacolorlogo },
              { black: venewoneblack, color: venewonecolor },
            ].map((logo, i) => (
              <div key={i} className="relative h-32 w-40">
                <img
                  src={logo.black}
                  className="absolute inset-0 h-full w-full transition-opacity duration-300 hover:opacity-0 rounded-2xl object-cover"
                  alt="Black Logo"
                />
                <img
                  src={logo.color}
                  className="absolute inset-0 h-full w-full opacity-0 transition-opacity duration-300 hover:opacity-100 rounded-2xl object-cover"
                  alt="Color Logo"
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Startupnews;
