import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

const Disclaimer = () => {
   useEffect(() => {
    document.title = "Disclaimer";
  }, []);
  return (
    <div className="bg-gray-200 py-10">
      <div className="max-w-4xl mx-auto bg-white md:p-10 p-5 rounded-lg shadow-md">
        {/* Header */}
        <h1 className="text-4xl font-bold text-center text-blue-700">Disclaimer</h1>
        <p className="text-center text-gray-600 mt-3 text-lg italic">
          Please read this disclaimer carefully before using our website.
        </p>

        {/* Sections */}
        {[
          {
            title: '📌 General Information',
            content: (
              <>
                The information provided by <strong>NewsNew</strong> on this website is for general informational purposes only. All content is published in good faith and for general knowledge. We do not make any warranties about the completeness, reliability, or accuracy of this information. Any action taken based on the information on this website is strictly at your own risk.
              </>
            ),
          },
          {
            title: '🔗 External Links Disclaimer',
            content:
              'Our website may contain external links to other websites that are not provided or maintained by us. We do not guarantee the accuracy, relevance, or reliability of any third-party websites linked from our platform.',
          },
          {
            title: '📢 Advertising & Promotions',
            content:
              'We may display advertisements and promotions on this website. These advertisements are provided by third-party advertising partners, and we are not responsible for the content, claims, or practices of these ads. Users are advised to do their own research before engaging with any advertised product or service.',
          },
          {
            title: '🔍 No Professional Advice',
            content:
              'The information on this website should not be considered professional advice in any field, including but not limited to legal, financial, health, or technical matters. If you need expert advice, please consult a qualified professional in that field.',
          },
          {
            title: '📰 Limitation of Liability',
            content:
              'We shall not be held responsible for any loss or damage arising from the use of this website. While we strive to provide accurate and up-to-date content, errors or outdated information may occur. Your use of this website is at your own discretion and risk.',
          },
          {
            title: '✅ Consent',
            content:
              'By using our website, you hereby consent to our disclaimer and agree to its terms.',
          },
          {
            title: '🔄 Changes & Updates',
            content:
              'We may update this disclaimer from time to time. Any changes will be published on this page, and users are encouraged to review it periodically.',
          },
          {
            title: '⚠️ Copyright & Fair Use',
            content:
              'All content published on NewsNew is either original, licensed, or used under fair use. Unauthorized reproduction, redistribution, or modification of any content without proper attribution is prohibited. If you believe any content infringes upon copyrights, please contact us for resolution.',
          },
        ].map((section, index) => (
          <div className="mt-8" key={index}>
            <h2 className="text-2xl font-bold text-gray-800">{section.title}</h2>
            <p className="text-gray-700 mt-3 leading-relaxed">{section.content}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Disclaimer;
