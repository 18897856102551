import React from "react";
import img from "../assets/logohighsize.avif";
import { ImWhatsapp } from "react-icons/im";
import { IoCall } from "react-icons/io5";

const Header = () => {
  return (
    <>
      <header className="flex flex-col max-md:mt-1 md:flex-row items-center  lg:p-2  lg:w-full md:p-6   p-1">
        
        <div className="flex items-center   ">
          <a href="/" >
          <img
            src={img}
            alt="newsnews"
            loading="lazy"
            className="w-14 md:w-16 lg:w-18 lg:ml-10 xl:ml-14 "
          />
             </a>
           <a href="/" >
          <h1 className="text-3xl md:text-2xl lg:text-3xl font-bold">
            <span className="text-[#2ade11] ">News</span>

            <span className="text-[#0c91cf]">New</span>
          </h1>
            </a>
        </div>

        <div className=" lg:w-[1100px] mt-2 max-md:mt-2 md:mt-3 w-full  flex flex-col items-center border-2 border-neutral-300  lg:ml-32   md:ml-10 md:w-[700px] lg:h-20 md:h-20 ">
          <h1 className="text-center text-sm md:text-sm lg:mt-2 md:mt-2">
            Post your any type of advertisement for free
          </h1>
          <div className="flex gap-3 items-center mt-2 ">
            <p className="text-lg md:text-xl">Inquiry now</p>

            <div className="text-blue-600 cursor-pointer">
              <a href="tel:+91 9114388358" className="underline">
                <IoCall size={20} />
              </a>
            </div>

            <div className="text-green-600 cursor-pointer">
              <a
                href="https://wa.me/919114388358?text=I%20need%20your%20services"
                className="text-green-600 cursor-pointer"
              >
                <ImWhatsapp size={20} />
              </a>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
