import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import { RiTwitterXFill } from "react-icons/ri";
import newsnewlogo from '../assets/logohighsize.avif'

const Footer = () => {
  return (
    <footer className="bg-gray-200 text-gray-900 py-10 mt-10 w-full ">
      <div className="max-w-6xl mx-auto px-4">
        <div className="grid md:grid-cols-3 gap-8 text-center md:text-left">
          <div>
            {/* Responsive Logo Section */}
            <div className="flex justify-center md:justify-start ">
              <img src={newsnewlogo} alt="NewsNew Logo" className="w-32 md:w-40 lg:w-40 h-auto rounded-full" loading="lazy" />
            </div>
            <p className="mt-2 text-gray-700">
              Your trusted digital platform for the latest news, event promotions, and advertisement solutions.  
              Stay informed, stay ahead! 🚀
            </p>
          </div>

          <div>
            <h2 className="text-xl font-bold text-gray-800">Quick Links</h2>
            <ul className="mt-2 space-y-2 text-gray-700">
              <li><a href="/latest-news" className="hover:underline hover:text-blue-500  active:underline active:text-blue-500">Latest News</a></li>
              <li><a href="/startupnewsdisplay" className="hover:underline hover:text-blue-500  active:underline active:text-blue-500">Startup Stories</a></li>
              <li><a href="/comic" className="hover:underline hover:text-blue-500  active:underline active:text-blue-500">Comic</a></li>
              <li><a href="/aboutus" className="hover:underline hover:text-blue-500  active:underline active:text-blue-500">About Us</a></li>
              <li><a href="/policy" className="hover:underline hover:text-blue-500  active:underline active:text-blue-500">Privacy Policy</a></li>
              <li><a href="/term" className="hover:underline hover:text-blue-500  active:underline active:text-blue-500">Terms and Conditions</a></li>
              <li><a href="/disclaimer" className="hover:underline hover:text-blue-500  active:underline active:text-blue-500">Disclaimer</a></li>
            </ul>
          </div>

          <div>
            <h2 className="text-xl font-bold text-gray-800">📢 Advertise With Us</h2>
            <p className="mt-2 text-gray-700">
              Want to boost your brand visibility? Get custom ad solutions for your business today!
            </p>
            <a
            href="tel:9114388358" 
              className="mt-3 inline-block bg-green-600 text-white font-semibold px-4 py-2 rounded-lg hover:bg-green-700 transition"
            >
              Call Now
            </a>
          </div>
        </div>

        <div className="border-t border-gray-300 my-6"></div>

        <div className="text-center">
          <h2 className="text-lg font-semibold text-gray-800">Connect With Us</h2>
          <div className="flex justify-center space-x-6 mt-3">
            <a href="https://www.facebook.com/profile.php?id=61574961455169" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-blue-600 transition active:text-blue-600">
              <FaFacebook size={28} />
            </a>
            <a href="https://www.instagram.com/newsnew01/" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-pink-500 transition active:text-pink-500">
              <FaInstagram size={28} />
            </a>
            <a href="https://x.com/newsnewtrend/" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-blue-400 transition active:text-blue-400">
              <RiTwitterXFill  size={28} />
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-blue-700 transition active:text-blue-700">
              <FaLinkedin size={28} />
            </a>
          </div>
        </div>

        <p className="mt-6 text-center text-sm text-gray-600">
          © 2025 News New. All Rights Reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;


