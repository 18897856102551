import React from 'react'

const Divider = () => {
  return (
    <div className='p-[0.5px] bg-slate-500 my-4  '>
      
    </div>
  )
}

export default Divider