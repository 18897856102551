import React, { useCallback, useEffect, useState } from "react";
import Axios, { storesAllApi } from "../common/storeapi";
import Divider from "../components/Divider";
import { Helmet } from "react-helmet-async"; 
const SkeletonLoader = () => (
  <div className="w-full max-w-5xl p-1  ">
    {[1, 2].map((index) => (
      <div key={index} className="bg-white shadow-lg rounded-lg p-6 animate-pulse">
        <div className="h-6 bg-gray-300 rounded w-3/4 mb-4"></div>
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-2/3 p-5">
            <div className="h-4 bg-gray-300 rounded w-full mb-2"></div>
            <div className="h-4 bg-gray-300 rounded w-5/6 mb-2"></div>
          </div>
          <div className="md:w-1/3 flex justify-center p-3">
            <div className="h-40 w-40 bg-gray-300 rounded-lg"></div>
          </div>
        </div>
        <div className="h-4 bg-gray-300 rounded w-4/5 p-3 mb-4"></div>
        <div className="h-40 bg-gray-300 rounded-lg w-full"></div>
      </div>
    ))}
  </div>
);

const Biography = () => {
  const [biopic, setBiopic] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchBiopicDetails = useCallback(async () => {
    const controller = new AbortController();
    const signal = controller.signal;

    try {
      const response = await Axios({
        url: storesAllApi.getLiveDetails.url,
        method: storesAllApi.getLiveDetails.method,
        signal,
        headers: {
          connection: "keep-alive",
        },
      });

      if (response.data.success) {
        setBiopic(response.data.data);
      } else {
        console.error("Failed to fetch Comic", response.data.message);
        setError("Failed to load comics");
      }
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error("Error fetching comic ", error);
        setError("🖊️ Writing history takes time… Bringing you incredible biographies!.");
      }
    } finally {
      setLoading(false);
    }

    return () => controller.abort();
  }, []);

  useEffect(() => {
    fetchBiopicDetails();
  }, [fetchBiopicDetails]);

  return (
    <>

<Helmet>
        <title> Inspiring Biographies </title>
        <meta
          name="description"
          content="Dive into the lives of inspiring figures—leaders, artists, visionaries—brought to life with rich stories and visuals. Only on NewsNew.in."
        />
        <meta
          name="keywords"
          content="biographies, famous people, inspirational stories, life stories, NewsNew"
        />
        <meta property="og:title" content="📖 Inspiring Biographies | NewsNew" />
        <meta
          property="og:description"
          content="Read engaging and well-crafted biographies from across the globe, featuring icons in politics, arts, science and beyond."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://newsnew.in/biograpy" />
        <link rel="canonical" href="https://newsnew.in/biograpy" />
      </Helmet>
     <div className="flex flex-col items-center  lg:p-8 min-h-screen">
      {loading ? (
        <SkeletonLoader />
      ) : error ? (
        <h1 className="text-xl text-red-500">{error}</h1>
      ) : biopic.length > 0 ? (
        <div className="md:grid-cols-2 gap-6 w-full max-w-5xl lg:-mt-10 mt-5 p-1 shadow ">
          {biopic.map((item) => (
            <div key={item._id} className="bg-white rounded-lg lg:p-8 ">
              <h1 className="text-2xl lg:text-4xl font-bold p-2 md:p-0 lg:p-0 text-balance">
                {item.liveheading}
              </h1>
              <div className="flex flex-col md:flex-row items-center ">
                <div className="md:w-2/3 p-3  lg:p-0 md:p-4">
                  <p className="text-gray-700 text-md ">{item.livefirstParagraph}</p>
                </div>
                <div className="md:w-1/3  flex justify-center p-5 lg:p-5 md:p-4 ">
                  <img
                    src={item.livefirstImage}
                    alt="Comic First"
                    className="w-full h-auto rounded-lg  "
                  />
                </div>
              </div>
              <p className="text-gray-700 p-3 text-lg  md:-mt-4 ">
                {item.liveSecondParagraph}
              </p>
              <div className=" border-yellow-300 p-3 aspect-[18/10]  md:aspect-[25/13] ">
                <img
                  src={item.liveSecondImage}
                  alt="Comic Second"
                  className="w-full    mb-3  rounded-2xl lg:rounded-2xl size-full "
                />
              </div>
             
              <Divider />
            </div>
          ))}
        </div>
      ) : (
        <h1 className="text-xl">🖊️ Writing history takes time… Bringing you incredible biographies!</h1>
      )}
    </div>
    </>
   
  );
};

export default Biography;
