import React from "react";
import Startupnews from "../../Pages/Startupnews";

const StartupNewsdisplay = () => {
  return (
    <div className="flex flex-col items-center min-h-screen bg-gray-100 p-4">
      <Startupnews />

      {/* Trending Section */}
      <div className="mt-6 w-full max-w-4xl bg-white shadow-lg rounded-2xl p-6">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">
          🔥 Trending Startup News
        </h2>
        <ul className="space-y-3">
          <li className="p-3 bg-gray-50 rounded-lg hover:bg-gray-200 transition">
           💡 ​Startup Mahakumbh 2025, India's largest innovation and entrepreneurship event, took place from April 3–5, 2025, at Bharat Mandapam, New Delhi.
          </li>
          <li className="p-3 bg-gray-50 rounded-lg hover:bg-gray-200 transition">
            📈 Fintech company becomes a unicorn in record time!
          </li>
          <li className="p-3 bg-gray-50 rounded-lg hover:bg-gray-200 transition">
            🌍 Green tech innovations are reshaping the industry.
          </li>
          <li className="p-3 bg-gray-50 rounded-lg hover:bg-gray-200 transition">
            💡 Startup exits with a $1.2B valuation—founder's journey revealed!
          </li>
        </ul>
      </div>

      {/* Featured Insights Section */}
      <div className="mt-6 w-full max-w-3xl bg-gradient-to-r from-green-400 to-blue-500 text-white text-center py-6 rounded-2xl shadow-lg">
        <h3 className="text-xl font-semibold">
          🚀 Exclusive Insights for Entrepreneurs
        </h3>
        <p className="mt-2 text-sm">
          Stay ahead with expert analysis, emerging market trends, and
          innovative business strategies.
        </p>
        <p className="mt-2 text-sm">
          Discover key factors behind startup success and lessons from industry
          leaders.
        </p>
      </div>

      {/* Success Stories Section */}
      <div className="mt-6 w-full max-w-3xl bg-white shadow-lg rounded-2xl p-6 text-center">
        <h3 className="text-xl font-bold text-gray-800">
          🏆 Startup Success Stories
        </h3>
        <p className="mt-2 text-gray-600">
          Be inspired by real-life entrepreneurs who turned ideas into
          billion-dollar businesses.
        </p>
        <div className="mt-4 space-y-3 text-left">
          <div className="p-4 bg-gray-50 rounded-lg">
            <h4 className="text-lg font-semibold">
              🔹 The Rise of Tech Titans
            </h4>
            <p className="text-sm text-gray-700">
              Learn how a small team of developers built an AI empire and scaled
              globally.
            </p>
          </div>
          <div className="p-4 bg-gray-50 rounded-lg">
            <h4 className="text-lg font-semibold">🔹 A Fintech Revolution</h4>
            <p className="text-sm text-gray-700">
              From garage startup to multi-million dollar IPO—how this fintech
              founder disrupted banking.
            </p>
          </div>
          <div className="p-4 bg-gray-50 rounded-lg">
            <h4 className="text-lg font-semibold">
              🔹 Green Innovation for a Sustainable Future
            </h4>
            <p className="text-sm text-gray-700">
              How an eco-conscious startup is making sustainable energy
              accessible worldwide.
            </p>
          </div>
          <div className="p-4 bg-gray-50 rounded-lg">
            <h4 className="text-lg font-semibold">
              🔹 ​Startup Mahakumbh 2025
            </h4>
            <p className="text-sm text-gray-700">
             Hearty congratulations to Elmentoz Research, a recognized Startup Odisha venture, for emerging as a winner of the Startup Mahakumbh Grand Challenge Award! .
            </p>
          </div>  <div className="p-4 bg-gray-50 rounded-lg">
            <h4 className="text-lg font-semibold">
              🔹 Fuel Your Innovation: Apply Now for the NBA Grant!
            </h4>
            <p className="text-sm text-gray-700">
             Startups recognized under Startup Odisha are now eligible to apply for the Need Based Assistance (NBA) Grant  a golden opportunity to supercharge growth and scale breakthrough innovations!
            </p>
          </div>  <div className="p-4 bg-gray-50 rounded-lg">
            <h4 className="text-lg font-semibold">
              🔹 Breaking Barriers: RostoVIT's Journey from Russia to Bharat
            </h4>
            <p className="text-sm text-gray-700">
             Meet Elena, the visionary founder of RostoVIT, who brought her groundbreaking startup all the way from Russia to shine at the SCO Pavilion during Startup Mahakumbh. 
            </p>
          </div>
        </div>
      </div>

      {/* Inquiry Section */}
      {/* Inquiry Section */}
      <div className="mt-6 w-full max-w-3xl bg-gradient-to-r from-purple-500 to-indigo-600 text-white text-center py-8 px-6 rounded-2xl shadow-xl">
        <h3 className="text-2xl font-extrabold">
          🌟 Share Your Startup Journey!
        </h3>
        <p className="mt-3 text-base">
          Inspire thousands with your success story. Get featured and let the
          world know your entrepreneurial journey!
        </p>
        <div className="mt-4 flex flex-col items-center">
          <a
            href="tel:+91 9114388358"
            className="mt-2 bg-white text-purple-600 font-semibold py-2 px-6 rounded-full shadow-md hover:bg-gray-200 transition"
          >
            📞 +91 9114388358
          </a>

          <a
            href="https://wa.me/919114388358?text=Hey%20there!%20My%20startup%20has%20an%20amazing%20story%20to%20tell.%20I'd%20love%20to%20share%20it%20with%20your%20audience.%20Let%20me%20know%20how%20to%20proceed!"
            target="_blank"
            rel="noopener noreferrer"
            className="mt-3 flex items-center bg-green-500 text-white font-semibold py-2 px-6 rounded-full shadow-md hover:bg-green-600 transition"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="mr-2"
            >
              <path d="M12 2C6.48 2 2 6.48 2 12c0 1.95.51 3.79 1.4 5.4L2 22l4.67-1.27A9.953 9.953 0 0 0 12 22c5.52 0 10-4.48 10-10S17.52 2 12 2zm.03 17.3c-1.76 0-3.43-.46-4.89-1.3l-.35-.21-2.76.75.79-2.63-.24-.38A8 8 0 1 1 12 20zm4.4-5.97c-.24-.12-1.42-.7-1.64-.78-.22-.08-.38-.12-.54.12s-.62.78-.76.94c-.14.16-.28.18-.52.06-.24-.12-1.03-.38-1.96-1.22-.72-.64-1.21-1.43-1.35-1.67-.14-.24-.01-.36.11-.48.11-.11.24-.28.36-.41s.16-.24.24-.4c.08-.16.04-.3-.02-.42-.06-.12-.54-1.3-.74-1.78-.2-.48-.4-.41-.54-.42h-.46c-.16 0-.42.06-.64.3s-.84.82-.84 2c0 1.18.86 2.31.98 2.48.12.17 1.69 2.66 4.1 3.72 1.44.62 2 .67 2.72.57.44-.06 1.42-.58 1.63-1.14.2-.56.2-1.04.14-1.14-.06-.1-.24-.16-.48-.28z"></path>
            </svg>
            Chat on WhatsApp
          </a>

          <a
            href="mailto:sahoodevraj25@gmail.com"
            className="mt-2 bg-white text-purple-600 font-semibold py-2 px-6 rounded-full shadow-md hover:bg-gray-200 transition"
          >
            📩 sahoodevraj25@gmail.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default StartupNewsdisplay;
