import React from "react";
import useSWR from "swr";
import Axios, { storesAllApi } from "../common/storeapi";
import Divider from "../components/Divider";
import { Helmet } from "react-helmet-async";
const fetcher = (url) => Axios({ url, method: "GET" }).then((res) => res.data);
const SkeletonLoader = () => (
  <div className="flex flex-col items-center  lg:p-8 min-h-screen">
    <div className="md:grid-cols-2 gap-6 w-full max-w-5xl lg:-mt-10 mt-5 p-1 shadow">
      {[...Array(2)].map((_, index) => (
        <div
          key={index}
          className="bg-white  rounded-lg lg:p-14 lg:-mb-16 animate-pulse"
        >
          <div className="h-6 bg-gray-300 rounded w-3/4 mb-4"></div>
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-2/3 p-5">
              <div className="h-4 bg-gray-300 rounded w-full mb-2"></div>
              <div className="h-4 bg-gray-300 rounded w-5/6 mb-2"></div>
            </div>
            <div className="md:w-1/3 flex justify-center p-3">
              <div className="h-40 w-40 bg-gray-300 rounded-lg"></div>
            </div>
          </div>
          <div className="h-4 bg-gray-300 rounded w-4/5 p-3 mb-4"></div>
          <div className="h-40 bg-gray-300 rounded-lg w-full"></div>
        </div>
      ))}
    </div>
  </div>
);

const Sport = () => {
  const { data, error } = useSWR(storesAllApi.getSportDetails.url, fetcher, {
    revalidateOnFocus: false,
  });

  if (!data) return <SkeletonLoader />;
  if (error)
    return (
      <h1 className="text-xl text-red-500">
        🏆 The game is still in play… Fetching the latest sports updates!.
      </h1>
    );
  if (data.success && data.data.length === 0)
    return (
      <h1 className="text-xl">
        ⏳ The scoreboard is updating… Hold tight for the latest action!
      </h1>
    );

  return (
    <>
      <Helmet>
        <title>Latest Sports News </title>
        <meta
          name="description"
          content="Catch up on trending sports events, match recaps, and highlights. Stay updated with your favorite teams, leagues, and players—right here on NewsNew!"
        />
        <meta
          name="keywords"
          content="Sports news, latest matches, football, cricket, tennis, sports updates, live scores, sports highlights"
        />
        <meta
          property="og:title"
          content="🏆 Sports News & Match Recaps | NewsNew"
        />
        <meta
          property="og:description"
          content="Breaking sports stories, highlights, and analysis of matches you love. Updated daily!"
        />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://newsnew.in/sports" />
      </Helmet>
      <div className="flex flex-col items-center justify-center lg:p-8 min-h-screen ">
        <div className="md:grid-cols-2 gap-6 w-full max-w-5xl lg:-mt-10 mt-5 p-1 shadow">
          {data.data.map((item) => (
            <div
              key={item._id}
              className="bg-white  rounded-lg lg:p-14 lg:-mb-16"
            >
              <h1 className="text-2xl lg:text-4xl font-bold mb-2 p-1 ">
                {item.sportName}
              </h1>
              <div className="flex flex-col md:flex-row items-center">
                <div className="p-5">
                  <p className="text-gray-700 mb-3 md:text-lg -mt-5">
                    {item.sportDesc}
                  </p>
                </div>
              </div>
              <div className="  p-2 lg:p-8 md:p-6">
                <img
                  src={item.sportImage}
                  alt={item.sportName}
                  loading="lazy"
                  className="w-full h-auto mb-2 -mt-6 rounded-2xl lg:rounded-2xl lg:-mt-10 md:-mt-8"
                />
              </div>
              <Divider />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Sport;
