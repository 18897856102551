import React, { useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { Menu, X } from "lucide-react"; 

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const closeMenu = () => setIsOpen(false);
  return (
    <>
      <nav className=" bg-[#3d348b] text-white   shadow-md mt-2 sticky top-0  z-50  flex lg:justify-center ">
        <div className="  ml-3    md:p-3 max-md:p-3   lg:p-0 flex w-full lg:justify-center ">
          {/* Menu Button */}
          <button onClick={() => setIsOpen(!isOpen)} className="  md:hidden  focus:outline-none ">
            {isOpen ? <X size={28} /> : <Menu size={28}  />}
          </button>
          
          <p className="ml-2 lg:hidden md:hidden animate-pulse  ">
             Gujarat Titans vs Rajasthan Royals
          </p>


        
        
          <ul
  className={`absolute lg:flex lg:justify-center lg:gap-8 top-16 left-0 w-full bg-[#3d348b] text-white md:static md:flex max-md:gap-0 md:w-full md:justify-center md:bg-transparent transition-all duration-300 ease-in-out max-md:text-center ${
    isOpen
      ? "opacity-100 max-h-[500px] py-4 -mt-5 max-md:min-h-screen"
      : "opacity-0 max-h-0 overflow-hidden max-md:h-0"
  } md:opacity-100 md:max-h-none md:py-0`}
>

            <li><NavLink to="/" onClick={closeMenu} className="block px-2    py-2 hover:bg-[#5247c6] max-md:text-lg">Home</NavLink></li>
            <li><NavLink to="/latest-news" onClick={closeMenu} className="block px-2 py-2 hover:bg-[#5247c6] max-md:text-lg">Latest News</NavLink></li>
            <li><NavLink to="/startupnewsdisplay" onClick={closeMenu} className="block px-2 py-2 hover:bg-[#5247c6] max-md:text-lg">Startups</NavLink></li>
            <li><NavLink to="/comic" onClick={closeMenu} className="block px-2 py-2 hover:bg-[#5247c6] max-md:text-lg">Comic</NavLink></li>
            <li><NavLink to="/event" onClick={closeMenu} className="block px-2 py-2 hover:bg-[#5247c6] max-md:text-lg">Events</NavLink></li>
            <li><NavLink to="/sports" onClick={closeMenu} className="block px-2 py-2 hover:bg-[#5247c6] max-md:text-lg">Sports</NavLink></li>
            <li><NavLink to="/politics" onClick={closeMenu} className="block px-2 py-2 hover:bg-[#5247c6] max-md:text-lg">Politics</NavLink></li>
            <li><NavLink to="/technology" onClick={closeMenu} className="block px-2 py-2 hover:bg-[#5247c6] max-md:text-lg">Technology</NavLink></li>
            <li><NavLink to="/biograpy" onClick={closeMenu} className="block px-2 py-2 hover:bg-[#5247c6] max-md:text-lg">Biography</NavLink></li> 
            {/* <li><NavLink to="/disclaimer" className="block px-2 py-2 hover:bg-[#5247c6]">Lifestyle</NavLink></li>  */}
            <li><NavLink to="/aboutus" onClick={closeMenu} className="block px-2 py-2 hover:bg-[#5247c6] max-md:text-lg">About Us</NavLink></li>

          
        
          </ul>
        </div>
      </nav>

      <Outlet />
    </>
  );
};

export default Navbar;
