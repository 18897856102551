

export const StartupData =[
    {
        "id": "Fydo",
        "name": "Fydo",
        "img": "/Image/logofydo.svg",
        "foundeddate": "2019",
        "category": [
          {
            "id": 1,
            "Heading": "Fydo: A Microsoft & NASSCOM-Backed Ad-Tech Startup Empowering Local Businesses",
            "firstPara" :"Fydo is on a mission to turn underrated local stores and small businesses into powerful brands. Leveraging its strong hyperlocal customer base, Fydo ensures these businesses get the digital boost they deserve bridging the gap between local popularity and wider recognition.",
            "secondPara": "Satyajeet Patnayak, alongside co-founders Dharam Chand Patnaik and Sinchan Mishra, is redefining the future of small businesses driven by purpose, powered by innovation, and fueled by a vision where every entrepreneur gets a chance to rise.",
            "imageone": "/Image/fydoimgone.avif",
            "thirdPara" : "In a world where big brands dominate with massive marketing budgets, small businesses often struggle to reach the right audience. They lack the investment, resources, and platforms to promote their products effectively. That’s where Fydo steps in giving local businesses the marketing power they deserve, without the hefty price tag. It empowers local stores and small businesses by leveraging its hyperlocal customer base to boost sales, increase visibility, and drive foot traffic—without requiring businesses to splurge on advertising. It offers tailor-made marketing tools designed to help these businesses grow while also ensuring a seamless last-mile service. Fydo supports businesses to maintain their expertise, with full support and specialized training. Fydo is best for newbies in the business field, to level up their company’s revenue. Customers get the tool for purchasing products at the lowest possible cost by opening just one app. No more hopping between apps to hunt the lowest price. They can take advantage of offers relevant to them, the explore area, curated notifications, event spaces, etc., in addition to discovering the offers and deals in various stores.",
            "secondHeading":"How Fydo Works: A Game-Changer for Local Commerce",
            "pointOne":" Real Time Local Deals & Discounts  Fydo provides customers with up-to-date offers and promotions from nearby stores, helping them find the best deals without switching between multiple apps.",
            "pointTwo" : " Bridging the Gap Between Businesses & Customers  Local enterprises can reach their ideal audience effortlessly, ensuring that their best deals get noticed.",
            "pointThree" :" One-Tap Shopping Convenience  Customers can explore discounts, receive curated notifications, and discover event spaces all in one place.",
            "imageTow":"/Image/fydoimgtwo.avif",
            "fouthPara" :"Fydo empowers businesses with comprehensive support and specialized training, ensuring they maximize their expertise and growth potential. For entrepreneurs and small businesses, Fydo serves as the ultimate launchpad, helping them increase revenue and establish a strong market presence. Customers, on the other hand, enjoy a seamless shopping experience, accessing the best deals, exclusive discounts, and curated offers—all within a single app. No more switching between multiple platforms to find the lowest prices—Fydo brings personalized offers, real-time notifications, and event-based deals straight to their fingertips. From discovering the hottest local discounts to exploring event spaces and trending promotions, Fydo is redefining smart shopping and business success in the hyperlocal market.",
            "fivePara" :" Fydo, a Microsoft and NASSCOM-backed hyperlocal ad-tech startup, is revolutionizing how small businesses market, grow, and connect with customers. With over 1,00,000 customer downloads, 10,500+ merchants, and 50+ PAN India brands, Fydo has positioned itself as a game-changer in the hyperlocal marketplace. Recognized among the top 10% of startups pitched at YCombinator, Fydo’s commitment to customer satisfaction has led to a 4.8-star rating on the Google Play Store, with over 50,000 offers redeemed to date. Its 100% month-over-month (MoM) growth rate is proof of its impact in helping merchants scale without the need for heavy advertising expenses." ,
            "imageThree" :"/Image/WhatsApp Image 2025-03-12 at 5.14.45 PM.avif",
            "sixPara" :" Having successfully raised a $100K pre-seed round, Fydo has reached a significant milestone of 1,00,000+ customers, further validating its product-market fit. Now, the company is gearing up for its next phase of expansion—raising its seed round to achieve a 1 million customer base across 10 cities in India. By offering tailor-made marketing tools and a seamless platform for discovering local deals, Fydo is not just supporting small businesses but also reshaping the future of hyperlocal commerce in India.",
            // product images
            "imageFour" :"/Image/fydoproductimageone.avif",
            "imageFive" :"/Image/fydoproductimagetwo.avif",
            "imagesix" : "/Image/fydoproductimgfour.avif",
            // founder info
            "imageSeven" :"/Image/FB_IMG_1741785795123.avif",
            "companyName" :"Fydo",
            "founderName" : "Satyajeet Patnayak",
            "Qoute": "Brands have money, small businesses have Fydo!." 

           
          
          }
        ]
      },
      
      {
        "id": "messoka",
        "name": "Messoka",
      "img": "/Image/messoka.avif",
        "foundeddate": "2025",
        "category": [
          {
            "id": 1,
            "Heading": "Messoka: Revolutionizing the Way You Find and Subscribe to Messes",
            "firstPara" :"In today’s fast-paced world, finding affordable and reliable meal options can be challenging, especially for students, working professionals, and individuals living away from home. Messoka is set to change that by offering a seamless platform to discover and subscribe to local messes with ease.",

            "secondPara": "Manish Murmu is on a mission to empower underserved communities through tech-driven solutions. With a vision rooted in impact and inclusion, he’s building pathways for small businesses and local innovators to thrive in the digital age.",

            // "imageone": "/src/Image/fydo-1024x764.jpg",
            "thirdPara" : "Messoka is an innovative app designed to bridge the gap between customers and mess services. Whether you’re looking for homely meals near you or need a hassle-free subscription plan, Messoka provides a one-stop solution. With a user-friendly interface, it allows individuals to explore messes based on location, menu, and pricing, making meal planning effortless.",
            "secondHeading":"Key Features of Messoka",
            "pointOne":" Find Nearby Messes – Easily discover messes offering daily meal services.",
            "pointTwo" : " Subscription-Based Meals – Choose and subscribe to meal plans that suit your needs.",
            "pointThree" :" Personalized Recommendations – Get suggestions based on your preferences and dietary requirements.Seamless Payment & Management – Manage your meal plans and payments hassle-free.",
            "imageTow":"/Image/messokaone.avif",
            "fouthPara" :"How Messoka is Solving a Major Problem -",
            "fivePara" :" Many individuals struggle with finding quality meals that fit their budget and lifestyle. Messoka eliminates the need for daily meal hunting by providing a platform where users can compare messes, select the best options, and manage their subscriptions in just a few clicks." ,
            // "imageThree" :"/src/Image/WhatsApp Image 2025-03-12 at 5.14.45 PM.avif",
            "sixPara" :" As the app gears up for its launch, the team behind Messoka is focused on enhancing user experience and onboarding more messes to expand meal options. With a vision to make meal planning convenient and accessible, Messoka is on its way to transforming how people experience daily dining.",
            // product images
            "imageFour" :"/Image/messokaproductsix.avif",
            "imageFive" :"/Image/messeokaimgfour.avif",
            "imagesix" : "/Image/messokaproductlast.avif",
            // founder info
            "imageSeven" :"/Image/messokafounderpic.avif",
            "companyName" :"Messoka",
            "founderName" : "Manish Murmu",
            "Qoute": "Bringing you a smarter to find and subscribe to messes ." 

           
          
          }
        ]
      },
     

      {
        
        "id": "venewone",
        "name": "Venewone",
        "img": "/Image/venewonegreen (1).avif",
        "foundeddate": "2024",
        "category": [
          {
            "id": 1,
            "Heading": "  Empowering Businesses Through Trusted Outsourcing Solutions  Venewone Solution Pvt Ltd",
            "firstPara" :"Venewone Solution Pvt Ltd is a leading provider of comprehensive outsourcing services, specializing in manpower supply, cleaning, and maintenance solutions. Established with a commitment to excellence, we pride ourselves on delivering tailored solutions that meet the diverse needs of our clients across various industries.At Venewone, we understand that every business has unique challenges and requirements. Our dedicated team of professionals is committed to working closely with clients to develop customized strategies that enhance operational efficiency and drive success. With a focus on quality, reliability, and innovation, we ensure that our services not only meet but exceed industry standards.With a strong emphasis on customer satisfaction, our mission is to build long-term partnerships with our clients by delivering exceptional service and value.",
            "secondPara": "Sonali Sahoo, together with co-founder Manoj Kumar Sahoo, is leading a new wave of entrepreneurship  where innovation meets purpose, and local ideas turn into global impact. Their vision To empower communities, uplift small businesses, and build a future that belongs to everyone.",
            "imageone": "/Image/venewoneimgone.avif",
            "thirdPara" : "To be the leading provider of outsourcing services, recognized for our innovation, integrity, and commitment to exceptional service. We strive to set new standards in the industry and contribute positively to the success of our clients and the communities we serve.At Venewone Solution Pvt Ltd, we envision a future where our outsourcing solutions not only enhance operational efficiency but also empower businesses to thrive in a rapidly evolving market. Our commitment to innovation drives us to continually explore new technologies and methodologies, ensuring that we remain at the forefront of industry advancements. We believe in the power of integrity, fostering transparent and ethical relationships with our clients, employees, and partners. Our dedication to exceptional service means we go above and beyond to understand the unique needs of each client, delivering solutions that create value and drive results. We also recognize our responsibility to the communities in which we operate. By promoting sustainable practices and contributing to social initiatives, we aim to make a positive impact beyond our business, helping to create healthier, more vibrant environments for all.",
            "secondHeading":"Your Trusted Partner in Operations",
            "pointOne":" Manpower Supply: We connect businesses with the right talent, offering skilled and unskilled workers for temporary, permanent, and project-based needs. Our recruitment process ensures that we provide candidates who are not only qualified but also align with your company’s culture and requirements.",
            "pointTwo" : "Cleaning Services: Our cleaning solutions are designed to maintain a clean and healthy environment. From daily maintenance to deep cleaning, our professional team uses advanced techniques and eco-friendly products to ensure your premises are spotless and welcoming.",
            "pointThree" :" Maintenance Services: We offer comprehensive maintenance services to keep your facilities and equipment in optimal condition. Our team is equipped to handle routine upkeep, emergency repairs, and preventive maintenance, helping you avoid costly disruptions and extend the lifespan of your assets.",
            "imageTow":"/Image/venewoneimgtwo.avif",
            "fouthPara" :"Our mission is to enhance operational efficiency and ensure a pristine environment for businesses and organizations. We are dedicated to offering top-notch services that are reliable, costeffective, and designed to exceed expectations.We believe in building lasting partnerships with our clients, working collaboratively to identify their unique challenges and tailor our services accordingly. Our commitment to reliability ensures that our clients can trust us to deliver on our promises, providing peace of mind as they focus on their core operations.Cost-effectiveness is at the heart of our mission. We continually seek innovative ways to optimize our services, enabling us to offer competitive pricing without compromising quality. By leveraging technology and efficient processes, we ensure our clients receive maximum value from their investment.",
            "fivePara" :"" ,
            "imageThree" :"/Image/venewoneimgthree.avif",
            "sixPara" :" Our goal is to set the industry standard for excellence in outsourcing by becoming the most trusted partner for businesses seeking comprehensive manpower, cleaning, and maintenance services. We strive to be a catalyst for our clients' success by offering innovative, reliable, and cost-effective solutions that address their unique challenges and help them achieve their operational and strategic objectives.",
            // product images
            "imageFour" :"/Image/venewoneproductone.avif",
            "imageFive" :"/Image/venewoneproductthree.avif",
            "imagesix" : "/Image/venewoneproductfour.avif",
            // founder info
            "imageSeven" :"/Image/venewone founder.avif",
            "companyName" :"Venewone",
            "founderName" : "Sonali Sahoo",
            "Qoute": "Innovation in outsourcing is not about cutting costs, it's about increasing value. That’s the principle that drives our every move." 

           
          
          }
        ]
      },


        
      
]