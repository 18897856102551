import React, { useEffect, useState, useCallback } from "react";
import { FaFacebook, FaRegCopy, FaWhatsapp } from "react-icons/fa";
import { MdCopyAll } from "react-icons/md";
import Axios, { storesAllApi } from "../../common/storeapi";
import toast from "react-hot-toast";
function PostShow() {
  const [post, setPost] = useState([]);
  const [promotePost, setPormotePost] = useState([]);
  const [copyMessage, setCopyMessage] = useState("");
  const fetchPromoteDetails = useCallback(async () => {
    try {
      const response = await Axios({
        url: storesAllApi.getPromoAllDetails.url,
        method: storesAllApi.getPromoAllDetails.method,
      });

      if (response.data.success) {
        setPormotePost(response.data.data);
      } else {
        console.error(
          "Failed to fetch promote details:",
          response.data.message
        );
      }
    } catch (error) {
      console.error("Error fetching latest news:", error);
    }
  }, []);

  const fetchOcussionDetails = useCallback(async () => {
    try {
      const response = await Axios({
        url: storesAllApi.getAllOccsionDetails.url,
        method: storesAllApi.getAllOccsionDetails.method,
      });

      if (response.data.success) {
        setPost(response.data.data);
      } else {
        console.error("Failed to fetch news:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching latest news:", error);
    }
  }, []);

  useEffect(() => {
    fetchOcussionDetails();
    fetchPromoteDetails();
  }, [fetchOcussionDetails, fetchPromoteDetails]);
  const shareOnSocial = (platform, item) => {
    const url = window.location.href;
    const heading = item.occusionName || item.promoName || "Check this out!";
    const text = `${heading}: ${url}`;
    const encodedText = encodeURIComponent(text);
    const encodedUrl = encodeURIComponent(url);

    let shareLink = "";

    switch (platform) {
      case "facebook":
        shareLink = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
        break;
      case "whatsapp":
        shareLink = `https://api.whatsapp.com/send?text=${encodedText}`;
        break;
      case "copy":
        navigator.clipboard
          .writeText(text)
          .then(() => {
            toast.success("Link copied to clipboard!");
          })
          .catch(() => {
            toast.error("Failed to copy link.");
          });
        return;
      default:
        return;
    }

    window.open(shareLink, "_blank");
  };

  return (
    <div className="gap-7 justify-center items-center p-5 md:p-6 md:grid lg:grid-cols-1 md:grid-cols-2 ">
      {post.map((item) => (
        <div
          key={item._id}
          className="bg-white max-w-md shadow-md rounded-2xl overflow-hidden p-5 border border-gray-300 hover:shadow-lg transition "
        >
          <h1 className="text-gray-900 font-bold text-xl text-center">
            {item.occusionName}
          </h1>
          <img
            src={item.occsionImage}
            alt={item.occusionName}
            className="w-full h-auto    mt-2  rounded-lg border-2 "
          />
          <p className="text-gray-700 font-semibold text-center text-lg mt-1">
            {item.occsionHostName}
          </p>
          <p className="text-gray-600 text-sm leading-relaxed mt-1">
            {item.occsionDesc}
          </p>
          <p className="text-gray-800 font-semibold mt-2 text-center">
            Share the link:
          </p>
          <div className="flex gap-4 mt-2 justify-center">
            <FaFacebook
              className="text-blue-600 text-3xl cursor-pointer hover:text-blue-400"
              onClick={() => shareOnSocial("facebook", item)}
            />
            <FaWhatsapp
              className="text-green-500 text-3xl cursor-pointer hover:text-green-300"
              onClick={() => shareOnSocial("whatsapp", item)}
            />
            <MdCopyAll
              className="text-gray-500 text-3xl cursor-pointer hover:text-gray-300"
              onClick={() => shareOnSocial("copy", item)}
            />
          </div>
        </div>
      ))}

      {promotePost.map((item) => {
        const dateObj = new Date(item.promoDate);
        const day = String(dateObj.getDate()).padStart(2, "0");
        const month = String(dateObj.getMonth() + 1).padStart(2, "0");
        const year = dateObj.getFullYear();

        let hours = dateObj.getHours();
        const minutes = String(dateObj.getMinutes()).padStart(2, "0");
        const ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12 || 12; // Convert 24-hour format to 12-hour format

        const formattedDate = `${day}-${month}-${year}`;
        const formattedTime = `${hours}:${minutes} ${ampm}`;

        return (
          <div
            key={item._id}
            className="bg-white max-w-md shadow-md rounded-2xl overflow-hidden p-4 border border-gray-300 hover:shadow-lg transition mt-8 md:mt-0"
          >
            <h1 className="text-gray-900 font-bold text-xl text-center">
              {item.promoName}
            </h1>
            <img
              src={item.promoImage}
              alt={item.promoName}
              className="w-full h-auto rounded-lg  mt-3  "
            />
            <p className="mt-3 text-center">Avaliable on </p>
            <p className="text-gray-700 font-semibold text-center text-lg">
              {formattedDate} {formattedTime}
            </p>
            {item.promoLink && (
              <a
                href={item.promoLink}
                target="_blank"
                rel="noopener noreferrer"
                className="block bg-blue-500 text-white px-4 py-2 mt-3 rounded-lg hover:bg-blue-600 text-center"
              >
                Visit Now
              </a>
            )}
            <p className="text-gray-800 font-semibold mt-3 text-center">
              Share the link:
            </p>
            <div className="flex gap-4 mt-2 justify-center">
              <FaFacebook
                className="text-blue-600 text-3xl cursor-pointer hover:text-blue-400"
                onClick={() => shareOnSocial("facebook", item)}
              />
              <FaWhatsapp
                className="text-green-500 text-3xl cursor-pointer hover:text-green-300"
                onClick={() => shareOnSocial("whatsapp", item)}
              />
              <MdCopyAll
                className="text-gray-500 text-3xl cursor-pointer hover:text-gray-300"
                onClick={() => shareOnSocial("copy", item)}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default PostShow;
