import React from "react";
import { useQuery } from "@tanstack/react-query";
import Axios, { storesAllApi } from "../common/storeapi";
import Divider from "../components/Divider";
import { Helmet } from "react-helmet-async";
const SkeletonLoader = () => (
  <div className="w-full max-w-5xl p-1">
    {[...Array(2)].map((_, index) => (
      <div key={index} className="bg-white shadow-lg rounded-lg p-6 animate-pulse">
        <div className="h-6 bg-gray-300 rounded w-3/4 mb-4"></div>
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-2/3 p-5">
            <div className="h-4 bg-gray-300 rounded w-full mb-2"></div>
            <div className="h-4 bg-gray-300 rounded w-5/6 mb-2"></div>
          </div>
          <div className="md:w-1/3 flex justify-center p-3">
            <div className="h-40 w-40 bg-gray-300 rounded-lg"></div>
          </div>
        </div>
        <div className="h-4 bg-gray-300 rounded w-4/5 p-3 mb-4"></div>
        <div className="h-40 bg-gray-300 rounded-lg w-full"></div>
      </div>
    ))}
  </div>
);

const fetchPoliticsDetails = async () => {
  const { data } = await Axios({
    url: storesAllApi.getPoliticsDetails.url,
    method: storesAllApi.getPoliticsDetails.method,
    headers: { connection: "keep-alive" },
  });
  if (!data.success) throw new Error("Failed to load Politics");
  return data.data;
};

const Politics = () => {
  const { data: politics = [], isLoading, isError } = useQuery({
    queryKey: ["politics"],
    queryFn: fetchPoliticsDetails,
    staleTime: 1000 * 60 * 5, // Cache data for 5 minutes
    retry: 2, // Retry failed requests twice
  });

  return (
    <>
      <Helmet>
        <title>Latest Political News </title>
        <meta
          name="description"
          content="Explore the latest Indian and global political developments. Get insights, opinions, and government updates on NewsNew.in."
        />
        <meta
          name="keywords"
          content="Politics news India, government updates, political parties, elections, parliament news, NewsNew politics"
        />
        <meta property="og:title" content="⚖️ Latest Political News | NewsNew.in" />
        <meta property="og:description" content="Stay updated with fresh political insights, election news, and key decisions from leaders—only on NewsNew." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://newsnew.in/politics" />
        <link rel="canonical" href="https://newsnew.in/politics" />
      </Helmet>
     <div className="flex flex-col items-center  lg:p-5 min-h-screen">
      {isLoading ? (
        <SkeletonLoader />
        
      ) : isError ? (
        <h1 className="text-xl p-5">⚖️ Decisions are being made… Stay tuned for breaking political news!.</h1>
      ) : politics.length > 0 ? (
        <div className="md:grid-cols-2 gap-6 w-full max-w-5xl  ">
          {politics.map((item) => (
            <div key={item._id} className="bg-white shadow-lg rounded-lg p-4  ">
              <h1 className="text-2xl lg:text-4xl font-bold ">{item.politicsName}</h1>
              <div className=" p-1  md:-mt-7 "><img class=" rounded-xl	shadow-lg md:shadow-none  aspect-[20/10] md:object-contain" alt={item.politicsImage}  src={item.politicsImage}/>
              </div>
              <div className="items-center">
                <div className="md:-mt-7">
                  <p className="text-gray-700 lg:text-lg md:text-lg">{item.politicsDesc}</p>
                </div>
              </div>
              {/* politicsImage */}
              <Divider />
            </div>
          ))}
        </div>
      ) : (
        <h1 className="text-xl">⚖️ Decisions are being made… Stay tuned for breaking political news!</h1>
      )}
    </div>
    </>
   
  );
};

export default Politics;