import React from "react";
import Techonolgydata from "../common/RandomData";
import Divider from "../components/Divider";
import { Helmet } from "react-helmet-async";
const Technology = () => {
  return (
    <>
      <Helmet>
        <title>Latest Tech News</title>
        <meta
          name="description"
          content="Catch the latest buzz in the world of technology — from AI to smartphones and futuristic gadgets. All curated on NewsNew.in."
        />
        <meta
          name="keywords"
          content="Technology news India, AI, gadgets, smartphones, tech updates, NewsNew technology"
        />
        <meta
          property="og:title"
          content="🖥️ Latest Tech News & Innovations | NewsNew"
        />
        <meta
          property="og:description"
          content="Explore trending tech stories and breakthroughs from India and around the world. Stay updated with NewsNew."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://newsnew.in/technology" />
        <link rel="canonical" href="https://newsnew.in/technology" />
      </Helmet>
      <div className="flex flex-col items-center justify-center lg:p-8 min-h-screen p-3  ">
        <div className="md:grid-cols-2 gap-6 w-full max-w-5xl lg:-mt-10 mt-5 p-1 shadow ">
          {Techonolgydata.data.map((item) => (
            <div
              key={item._id}
              className="bg-white rounded-lg lg:p-14 lg:-mb-16 "
            >
              <h1 className="text-2xl lg:text-4xl font-bold mb-2 p-1">
                {item.techName}
              </h1>
              <div className="flex flex-col md:flex-row items-center">
                <div className="p-5">
                  <p className="text-gray-700 mb-3 lg:text-lg md:text-lg -mt-5">
                    {item.techDesc}
                  </p>
                </div>
              </div>
              <div className="  aspect-[18/10]  md:aspect-[25/10]">
                <img
                  src={item.techImage}
                  alt={item.techName}
                  className="size-full object-cover md:object-fill lg:objct-fill  rounded-2xl  lg:rounded-2xl  "
                />
              </div>
              <Divider />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Technology;
