import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { StartupData } from '../common/startupData';
import { motion } from "framer-motion";
import { FaFacebook, FaLinkedin } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaSquareXTwitter } from "react-icons/fa6";

const CategorywiseStartupNews = () => {
  const { startupId } = useParams();
  const [showFounder, setShowFounder] = useState(false);

  const startup = StartupData.find((item) => item.id.toString() === startupId);

  const currentUrl = typeof window !== 'undefined' ? window.location.href : '';

  useEffect(() => {
    if (showFounder) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => document.body.classList.remove("overflow-hidden");
  }, [showFounder]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!startup) {
    return <div className="text-center text-red-500 font-bold text-xl">Data not found</div>;
  }

  const firstCategory = startup.category?.[0];
  const firstImageUrl = firstCategory?.imageone || "";
  const pageTitle = `${firstCategory?.companyName || "Startup News"} | NewsNew`;
  const pageDescription = firstCategory?.Heading || "Read the latest startup success stories and news.";

  const socialLinks = [
    {
      icon: <FaFacebook />,
      color: "bg-[#3B5998]",
      url: `https://www.facebook.com/sharer/sharer.php?u=${currentUrl}&title=${encodeURIComponent(pageTitle)}&summary=${encodeURIComponent(pageDescription)}&source=NewsNew`,
      label: "Facebook"
    },
    {
      icon: <FaLinkedin />,
      color: "bg-[#0077B5]",
      url: `https://www.linkedin.com/shareArticle?mini=true&url=${currentUrl}&title=${encodeURIComponent(pageTitle)}&summary=${encodeURIComponent(pageDescription)}&source=NewsNew`,
      label: "LinkedIn"
    },
    {
      icon: <IoLogoWhatsapp />,
      color: "bg-green-500",
      url: `https://api.whatsapp.com/send?text=${encodeURIComponent(`${pageTitle}\n${pageDescription}\n${currentUrl}`)}`,
      label: "WhatsApp"
    },
    {
      icon: <FaSquareXTwitter />,
      color: "bg-gray-800",
      url: `https://twitter.com/intent/tweet?text=${encodeURIComponent(pageTitle)}&url=${currentUrl}`,
      label: "Twitter"
    },
  ];

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:type" content="article" />
        <meta property="og:image" content={firstImageUrl} />
      </Helmet>

      <div className="flex lg:flex justify-end items-start lg:-mt-3 lg:p-3 min-h-screen">
        {/* Main Content */}
        <div className="max-w-4xl w-full mx-auto bg-gray-100 lg:p-11 md:p-10 rounded shadow-md p-6">
          {startup.category?.map((cat, index) => (
            <div key={index}>
              <h1 className="lg:text-4xl md:text-3xl max-md:text-2xl font-bold text-gray-800">{cat.Heading}</h1>
              <p className="mt-3 lg:text-lg font-serif">{cat.firstPara}</p>
              <p className="mt-3 lg:text-lg font-serif">{cat.secondPara}</p>
              {cat.imageone && (
                <img className="w-full rounded-lg shadow-md mt-4" src={cat.imageone} alt={cat.Heading || "Startup news image"} />
              )}
              <p className="mt-3 lg:text-lg font-serif">{cat.thirdPara}</p>
              <h2 className="font-bold text-2xl mt-5">{cat.secondHeading}</h2>

              <div className="ml-4 mt-4 md:text-lg lg:text-lg space-y-2">
                {cat.pointOne && <p>✅ {cat.pointOne}</p>}
                {cat.pointTwo && <p>✅ {cat.pointTwo}</p>}
                {cat.pointThree && <p>✅ {cat.pointThree}</p>}
              </div>

              {cat.imageTow && (
                <img className="mt-6 rounded-2xl shadow-md md:h-96 w-full object-cover" src={cat.imageTow} alt="Startup milestone" />
              )}
              <p className="mt-5 lg:text-lg font-serif">{cat.fouthPara}</p>
              <p className="mt-5 lg:text-lg font-serif">{cat.fivePara}</p>
              {cat.imageThree && (
                <img className="mt-6 rounded-2xl shadow-md" src={cat.imageThree} alt="Startup journey" />
              )}
              <p className="mt-5 lg:text-lg font-serif">{cat.sixPara}</p>

              {/* Image Grid */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mt-6">
                {cat.imageFour && <img src={cat.imageFour} className="object-cover w-full h-60 rounded-lg border" alt="Gallery 1" />}
                {cat.imageFive && <img src={cat.imageFive} className="object-cover w-full h-60 rounded-lg border" alt="Gallery 2" />}
                {cat.imagesix && <img src={cat.imagesix} className="object-cover w-full h-60 rounded-lg border" alt="Gallery 3" />}
              </div>

              <p className="mt-5 font-bold text-center italic">Your Story, Our Inspiration</p>

              {/* Social Media Links */}
              <div className="flex justify-center items-center space-x-3 py-4">
                {socialLinks.map((link, idx) => (
                  <motion.a
                    key={`${idx}-${link.url}`}
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={`Share on ${link.label}`}
                    className={`w-12 h-12 flex items-center justify-center rounded-full shadow-lg transition-all duration-300 hover:scale-110 hover:shadow-xl ${link.color}`}
                    whileHover={{ rotate: 10, scale: 1.2 }}
                    whileTap={{ scale: 0.9 }}
                  >
                    <motion.div className="text-white text-2xl">{link.icon}</motion.div>
                  </motion.a>
                ))}
              </div>
            </div>
          ))}
        </div>

        {/* Founder - Desktop */}
        <div className="hidden lg:block w-68 bg-red-100 shadow rounded-xl p-4 absolute">
          {startup.category?.map((cat, index) => (
            <div key={index}>
              <h1 className="text-3xl font-semibold text-center break-words truncate max-w-full px-2">{cat.companyName}</h1>
              <div className="flex justify-center mt-2">
                {cat.imageSeven && (
                  <img src={cat.imageSeven} alt="Founder" className="w-60 h-60 rounded-xl border-4 border-gray-300 object-cover" />
                )}
              </div>
              <p className="text-xl font-semibold text-center text-gray-700 mt-2">{cat.founderName}</p>
              <p className="text-xl font-semibold text-center text-gray-700">CEO</p>
              <p className="text-center font-medium text-gray-600 italic mt-2">"{cat.Qoute}"</p>
            </div>
          ))}
        </div>

        {/* Founder Button - Mobile */}
        <div className="lg:hidden fixed bottom-5 right-5 z-50">
          <button
            onClick={() => setShowFounder(!showFounder)}
            className="bg-red-500 text-white px-4 py-2 rounded-full shadow-lg font-semibold text-sm hover:bg-red-600 transition"
          >
            Show Founder Details
          </button>
        </div>

        {/* Founder Modal - Mobile */}
        {showFounder && (
          <div className="lg:hidden fixed inset-0 flex justify-center items-center bg-black/40 z-[60] p-5">
            <div className="bg-white rounded-xl shadow-xl w-11/12 max-w-md p-6 relative">
              <button
                onClick={() => setShowFounder(false)}
                className="absolute top-2 right-3 text-2xl text-gray-600 hover:text-black"
              >
                &times;
              </button>
              {startup.category?.map((cat, index) => (
                <div key={index} className="text-center">
                  <h1 className="text-2xl font-bold font-serif text-gray-800">{cat.companyName}</h1>
                  <div className="flex justify-center p-2">
                    {cat.imageSeven && (
                      <img src={cat.imageSeven} alt="Founder" className="rounded-xl border-4 border-gray-300 object-cover" />
                    )}
                  </div>
                  <p className="text-lg font-semibold text-gray-700 mt-2">{cat.founderName}</p>
                  <p className="text-lg font-semibold text-gray-700">CEO</p>
                  <p className="text-gray-600 italic">"{cat.Qoute}"</p>
                  <button
            onClick={() => setShowFounder(!showFounder)}
            className="bg-red-500 text-white px-4 py-2 rounded-full shadow-lg font-semibold text-sm hover:bg-red-600 transition mt-2"
          >
            {showFounder ? "Hide Founder Details" : "Show Founder Details"}
          </button>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {/* Contact CTA */}
      <div className='flex justify-center p-2'>
        <div className="mt-6 w-full max-w-3xl bg-gradient-to-r from-purple-500 to-indigo-600 text-white text-center py-8 px-6 rounded-2xl shadow-xl">
          <h3 className="text-2xl font-extrabold">🌟 Share Your Startup Journey!</h3>
          <p className="mt-3 text-base">
            Inspire thousands with your success story. Get featured and let the world know your entrepreneurial journey!
          </p>
          <div className="mt-4 flex flex-col items-center">
            <a href="tel:+919114388358" className="mt-2 bg-white text-purple-600 font-semibold py-2 px-6 rounded-full shadow-md hover:bg-gray-200 transition">
              📞 +91 9114388358
            </a>
            <a
              href="https://wa.me/919114388358?text=Hey%20there!%20My%20startup%20has%20an%20amazing%20story%20to%20tell.%20I'd%20love%20to%20share%20it%20with%20your%20audience.%20Let%20me%20know%20how%20to%20proceed!"
              target="_blank"
              rel="noopener noreferrer"
              className="mt-3 flex items-center bg-green-500 text-white font-semibold py-2 px-6 rounded-full shadow-md hover:bg-green-600 transition"
            >
              💬 Chat on WhatsApp
            </a>
            <a href="mailto:sahoodevraj25@gmail.com" className="mt-2 bg-white text-purple-600 font-semibold py-2 px-6 rounded-full shadow-md hover:bg-gray-200 transition">
              📩 sahoodevraj25@gmail.com
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategorywiseStartupNews;
