import React, { useState } from 'react';
import { MdOutlineArrowCircleDown } from "react-icons/md";
import PostRequestForm from './PostForm';
import PostShow from './PostShow';

const PostDisplay = () => {
    const [openForm, setOpenForm] = useState(false);

    return (
        <>
            {!openForm ? (
                <div 
                    className='border-1 min-w-[300px] min-h-18 shadow bg-[#7678ed] text-white rounded-xl cursor-pointer -mt-10  '
                    onClick={() => setOpenForm(true)}
                >
                    <div className='text-center mt-4 '>
                        <h1 className='text-lg font-bold max-md:text-sm max-md:flex max-md:justify-center '>Post your occasions for free Like this</h1>
                        <div className='mt-2 flex justify-center mb-2 animate-bounce text-white'>
                            <MdOutlineArrowCircleDown className='bg-blue-800 rounded-4xl' size={30} />
                        </div>
                    </div>
                </div>
            ) : (
                
                  <PostRequestForm setOpenForm={setOpenForm} />
              
               
            )}


            <div className=''>
                <PostShow />
            </div>
        </>
    );
};

export default PostDisplay;
