import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import Homes from "./Pages/Home";
import Sport from "./Pages/Sport";
import ReactGA from "react-ga";
import LatestNew from "./Pages/LatestNew";
import PostDisplay from "./components/Advertisemet/PostDisplay";
import Footer from "./components/Footer";
import StartupNewsdisplay from "./components/Advertisemet/StartupNewsdisplay";
import CategorywiseStartupNews from "./Pages/CategorywiseStartupNews";
import Startupnews from "./Pages/Startupnews";
import ScrollToTop from "./utils/ScrollToTop";
import Comic from "./Pages/Comic";
import Events from "./Pages/Events";
import Politics from "./Pages/Politics";
import Advertisement from "./Pages/Advertisement";
import Disclaimer from "./Pages/Disclaimer";
import Aboutus from "./Pages/Aboutus";
import ContactUs from "./Pages/ContactUs";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Termandcondition from "./Pages/Termandcondition";
import Technology from "./Pages/Technology";
import Biography from "./Pages/Biography";
import { Helmet } from "react-helmet-async";

const TRACKING_ID="G-F8C7G2714V";
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <>
        <Helmet>
            <title>latest news today</title>
            <meta name="description" content="Stay updated with the latest headlines, startup news, politics, and more." />
          </Helmet>
      <Router>
        <Header />
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<Homes />} />
          <Route path="/latest-news" element={<LatestNew />} />
          <Route path="/post-display" element={<PostDisplay />} />
          <Route path="/startupnewsdisplay" element={<StartupNewsdisplay />} />
          <Route path="/startupnews" element={<Startupnews />} />
          <Route path="/startupnews/:startupId" element={<CategorywiseStartupNews />} />
          <Route path="/comic" element={<Comic />} /> 
          <Route path="/event" element={<Events />} /> 
          <Route path="/sports" element={<Sport />} />
          <Route path="/politics" element={<Politics />} />
          <Route path="/technology" element={<Technology />} />
          <Route path="/biograpy" element={<Biography />} />
          {/* <Route path="/advertisement" element={<Advertisement />} /> */}
          <Route path="/aboutus" element={<Aboutus />} />
          {/* <Route path="/contactus" element={<ContactUs />} /> */}
          <Route path="/policy" element={<PrivacyPolicy />} />
          <Route path="/term" element={<Termandcondition />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
       
          
        </Routes>
        <Footer />
      </Router>
      <Toaster />
    </>
  );
}

export default App;

