import React, { useCallback, useEffect, useState } from "react";
import Axios, { storesAllApi } from "../common/storeapi";
import Divider from "../components/Divider";
import { Helmet } from "react-helmet-async";

const SkeletonLoader = () => (
  <div className="w-full max-w-5xl p-1  ">
    {[1, 2].map((index) => (
      <div key={index} className="bg-white shadow-lg rounded-lg p-6 animate-pulse">
        <div className="h-6 bg-gray-300 rounded w-3/4 mb-4"></div>
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-2/3 p-5">
            <div className="h-4 bg-gray-300 rounded w-full mb-2"></div>
            <div className="h-4 bg-gray-300 rounded w-5/6 mb-2"></div>
          </div>
          <div className="md:w-1/3 flex justify-center p-3">
            <div className="h-40 w-40 bg-gray-300 rounded-lg"></div>
          </div>
        </div>
        <div className="h-4 bg-gray-300 rounded w-4/5 p-3 mb-4"></div>
        <div className="h-40 bg-gray-300 rounded-lg w-full"></div>
      </div>
    ))}
  </div>
);

const Comic = () => {
  const [comic, setComic] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchComic = useCallback(async () => {
    const controller = new AbortController();
    const signal = controller.signal;

    try {
      const response = await Axios({
        url: storesAllApi.getAllComicDataDetails.url,
        method: storesAllApi.getAllComicDataDetails.method,
        signal,
        headers: {
          connection: "keep-alive",
        },
      });

      if (response.data.success) {
        setComic(response.data.data);
      } else {
        console.error("Failed to fetch Comic", response.data.message);
        setError("Failed to load comics");
      }
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error("Error fetching comic ", error);
        setError("The story is still being inked... Hold on for the magic!");
      }
    } finally {
      setLoading(false);
    }

    return () => controller.abort();
  }, []);

  useEffect(() => {
    fetchComic();
  }, [fetchComic]);

  return (
    <>
 <Helmet>
        <title>Comic Stories </title>
        <meta name="description" content="Read engaging and creative comic-style stories that bring news and imagination together. Follow visual storytelling through art and narrative." />
        <meta name="keywords" content="comic stories, illustrated news, graphic storytelling, fun news, NewsNew comics" />
        <meta name="author" content="NewsNew" />

        {/* Open Graph */}
        <meta property="og:title" content="🖼️ Comic Stories | Illustrated Tales by NewsNew" />
        <meta property="og:description" content="Discover unique visual storytelling through comics. Dive into creative tales blending facts, fun, and illustration." />
        <meta property="og:image" content="https://newsnew.in/images/comic-og.jpg" />
        <meta property="og:url" content="https://newsnew.in/comic" />
        <meta property="og:type" content="article" />

        {/* Twitter Cards */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Read Comic Stories – Fun & Illustrated News | NewsNew" />
        <meta name="twitter:description" content="Explore the lighter side of news with visual storytelling and fun comic-style narratives." />
        <meta name="twitter:image" content="https://newsnew.in/images/comic-og.jpg" />
      </Helmet>

      <div className="flex flex-col items-center  lg:p-8 min-h-screen">
      {loading ? (
        <SkeletonLoader />
      ) : error ? (
        <h1 className="text-xl text-red-500">{error}</h1>
      ) : comic.length > 0 ? (
        <div className="md:grid-cols-2 gap-6 w-full max-w-5xl lg:-mt-10 mt-5 p-1 ">
          {comic.map((item) => (
            <div key={item._id} className="bg-white  rounded-lg lg:p-8 ">
              <h1 className="text-2xl lg:text-4xl font-bold p-2 md:p-0 lg:p-0 text-balance">
                {item.comicHeading}
              </h1>
              <div className="flex flex-col md:flex-row items-center">
                <div className="md:w-2/3 p-3  lg:p-0 md:p-4">
                  <p className="text-gray-700 text-md ">{item.comicFirstParagraph}</p>
                </div>
                <div className="md:w-1/3  flex justify-center p-4 lg:p-5 md:p-4">
                  <img
                    src={item.comicFirstImage}
                    alt="Comic First"
                    className="w-full h-auto  rounded-lg object-cover"
                  />
                </div>
              </div>
              <p className="text-gray-700 p-2 text-lg  md:-mt-4">
                {item.comicSecondParagraph}
              </p>
              <div className="mt-6  p-3 lg:p-8 md:p-6 ">
                <img
                  src={item.comicSecondImage}
                  alt="Comic Second"
                  className="w-full md:h-64  lg:h-64 mb-3 -mt-6 rounded-2xl lg:rounded-2xl lg:-mt-10 md:-mt-8 object-contain"
                />
              </div>
              <p className="flex items-center justify-center md:text-2xl text-xl lg:-mt-6 md:-mt-4 -mt-2 ">
                "End"
              </p>
              <Divider />
            </div>
          ))}
        </div>
      ) : (
        <h1 className="text-xl">The story is still being inked... Hold on for the magic!</h1>
      )}
    </div>
  
    </>
 
  );
};

export default Comic;



























